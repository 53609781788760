import { takeEvery, put, call  } from 'redux-saga/effects';
import { WEBINARS_ACTIONS } from '../constants';
import { webinarsActions } from '../actions';
import { processRequest } from '../services/Api';

function* handleGetWebinars(action) {
  const {payload} = action || {};
  const {meta} = payload || {};
  try {
    const { data } = yield call(processRequest, `/webinars?page=${meta.page}`, 'GET');

    if(data.webinars) {
      const getWebinarsSuccess = meta.page === 1 ? webinarsActions.getWebinarsSuccess : webinarsActions.getWebinarsNextPageSuccess;
      yield put(getWebinarsSuccess(data.webinars, data.total_pages));
    }
  } catch (e) {
    console.log(e);
    yield put(webinarsActions.sendSuggestionError(e));
  }
}

export function* watchGetWebinarsSagas() {
  yield takeEvery(WEBINARS_ACTIONS.GET_WEBINARS, handleGetWebinars);
};