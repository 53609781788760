import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { webinarsActions, globalActions } from '../../actions';
import { Webinars } from '../../components';

const mapStateToProps = ({webinarsState, globalState}) => {
  return {
    webinars: webinarsState.webinars,
    meta: webinarsState.meta,
    globalState: globalState,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getWebinars: bindActionCreators(webinarsActions.getWebinars, dispatch),
    globalActions: bindActionCreators(globalActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Webinars);