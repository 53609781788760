import React, { Component } from 'react'
import classNames from 'classnames';
import { LOCAL_STORAGE } from '../../../constants';
import { AccountForm } from '../../ui/Forms/Profile/AccountForm';
import { InformationForm } from '../../ui/Forms/Profile/InformationForm';
import { ChangePasswordForm } from '../../ui/Forms/Profile/ChangePasswordForm';

const TABS = ['account', 'info', 'password'];

class Profile extends Component {

  tabs = {
    0: AccountForm,
    1: InformationForm,
    2: ChangePasswordForm
  };

  states = {
    0: 'account',
    1: 'information',
    2: 'password'
  }

  constructor(props) {
    super(props);

    const activeTabIndex = 1;

    this.state = {
      activeTabIndex,
      formName: this.states[activeTabIndex],
      account: {
        state: { ...this.collectDataForForm(this.props.currentUser || {}, 0) },
        errors: {}
      },
      information: {
        state: { ...this.collectDataForForm(this.props.currentUser || {}, 1) },
        errors: {}
      },
      password: {
        state: { ...this.collectDataForForm(this.props.currentUser || {}, 2) },
        errors: {}
      }
    }
  }

  componentDidMount() {
    this.props.currentUserActions.getCurrentUser();
  }

  componentDidUpdate(prevProps) {
    const { currentUser, message } = this.props;

    if (currentUser !== prevProps.currentUser) {
      this.collectAllData(currentUser);
    }

    if(message !== prevProps.message) {
      this.timeout = setTimeout(this.clearMessage, 3000);
    }
  }

  clearMessage = () => {
    const { currentUserActions } = this.props;

    currentUserActions.updateCurrentUserSuccess();
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { activeTabIndex } = this.state;
    const errors = this._validate();

    if (errors) {
      return this._addErrorsForCurrentForm(errors);
    } else {
      this._addErrorsForCurrentForm({});
    }

    if(this.states[activeTabIndex] !== this.states[2])
      this.props.currentUserActions.updateCurrentUser(this._currentState())
    else
      this.props.authActions.changePassword(this._currentState());
  }

  onChangeTab = activeTabIndex => {
    this.clearMessage();
    this.setState(
      { activeTabIndex, formName: this.states[activeTabIndex], }
    );
  }

  onChangeForm = ({ name, value }, e) => {
    const { formName } = this.state;
    this.setState({
      [formName]: {
        ...this.state[formName],
        state: {
          ...this.state[formName].state,
          [name]: value,
          errors: {}
        }
      }
    });
  }

  inputProps = (name) => {
    const { formName } = this.state;
    const form = this.state[formName]
    let error = form.errors[name] ? form.errors[name].join(' ') : null;

    const frontendErrors = this._currentErrors();
    const backendErrors = this.props.updateErrors;

    if (!backendErrors) {
      error = frontendErrors[name];
    } else {
      error = backendErrors[name];
    }

    return {
      name,
      error,
      onChange: this.onChangeForm,
      value: form.state[name],
      classes: { wrapper: 'p-form-item', error: 'error-text' }
    }
  }

  CurrentTab = () => {
    const unparsedUser = localStorage.getItem(LOCAL_STORAGE.USER);
    const user = JSON.parse(unparsedUser);
    const { activeTabIndex } = this.state;
    const Tab = this.tabs[activeTabIndex];

    if (!Tab) {
      return null;
    }
    const currentState = this._currentState();
    const frontendErrors = this._currentErrors();
    const backendErrors = this.props.updateErrors;
    const passwordError = this.props.changePasswordErrors;
    let errors;

    if (!backendErrors) {
      errors = frontendErrors;
    } else {
      errors = backendErrors;
    }

    return <Tab
      inputProps={this.inputProps}
      onSubmit={this.onSubmit}
      formData={currentState}
      currentUser={this.props.currentUser}
      errors={errors}
      onChange={this.onChangeForm}
      message={this.props.message || this.props.successPasswordMessage}
      user={user}
      passwordError={ passwordError }
    />;
  }

  collectDataForForm = (dataToScrap, activeTabIndex) => {
    const Tab = this.tabs[activeTabIndex];

    return Tab.fields.reduce((acc, field) => {
      return { ...acc, [field]: dataToScrap[field] }
    }, {})
  }

  collectAllData = (dataToScrap) => {
    const dataToChange = { ...this.state };
    Object.keys(this.states).forEach((stateIndex) => {
      dataToChange[this.states[stateIndex]].state = {
        ...dataToChange[this.states[stateIndex]].state,
        ...this.collectDataForForm(dataToScrap, stateIndex)
      }
    });

    this.setState({
      dataToChange
    });
  }

  _validate() {
    const { activeTabIndex, formName } = this.state;
    const Tab = this.tabs[activeTabIndex];
    const { state } = this.state[formName];
    return Tab.validate(state);
  }

  _addErrorsForCurrentForm(errors) {
    const { formName } = this.state;
    this.setState({
      [formName]: {
        ...this.state[formName],
        errors: errors || {}
      }
    });
  }

  _renderTabs = () => {
    const { activeTabIndex } = this.state;

    return TABS.map((tab, index) => (
      <div
        key={ index }
        onClick={() => this.onChangeTab(index)}
        className={classNames("tab-btn",  { active: activeTabIndex === index } )}
      >
        {tab}
      </div>
    ))
  }

  _currentState = () => this.state[this.state.formName].state;
  _currentErrors = () => this.state[this.state.formName].errors;

  componentWillUnmount() {
    this.clearMessage();
    clearTimeout(this.timeout);
  }

  render() {
    return (
      <main>
        <section className="sec-profile">
          <div className="container">
            <div className="heading">
              <p>my profile</p>
            </div>
            <div className=" profile-container">
              <div className="profile--tabs-btn">
                {this._renderTabs()}
              </div>
              <div className="profile--tabs-container">
                <this.CurrentTab />
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default Profile;
