import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ShowIcon from '../../../imgs/show-icon.svg';
import classNames from 'classnames';

const Input = ({
  onInputChange,
  value,
  type,
  placeholder,
  styleClass,
  error,
  forgotPasswordPath,
  onShowPassword,
  showPassword,
  onShowPasswordConfirmation,
  showPasswordConfirmation,
  isHalf,
  hideIcon,
  maxLength
}) => {
  const props = maxLength ? {maxLength} : {};
  return (
    <div className={ `form-row ${ isHalf ? 'half' : '' }` }>
      <input
        {...props}
        type={ type === 'password' && (showPassword || showPasswordConfirmation) ? 'text' : type }
        placeholder={ placeholder }
        className={ styleClass }
        onChange={ onInputChange }
        value={ value }
        // maxLength={ maxLength ? maxLength : 120 }
      />
      { type === 'password' && !hideIcon &&
        <img
          src={ ShowIcon }
          alt="show"
          onClick={ onShowPassword || onShowPasswordConfirmation }
        />
      }
      <span className='error-text'>{ error || 'Error' }</span>
      { forgotPasswordPath &&
        <Link to={ forgotPasswordPath } className="text">Forgot password?</Link>
      }
    </div>
  );
}

const TOOGLE_PASSWORD_TYPE = {
  text: 'password',
  password: 'text'
};

export const PlainInput = ({
  name,
  value,
  error,
  label,
  onChange,
  type,
  disabled = false,
  hideIcon = false,
  classes = { wrapper: 'p-form-item', label: '', input: '', error: 'error-text' }
}) => {

 const [innerType, setInterType] = useState(type);

  const inputOnChange = (e) => {
    onChange({ name, value: e.currentTarget.value }, e);
  }

  return (
     <div className={classes.wrapper}>
       <label className={classes.label}>{label}</label>
       <input
         type={innerType}
         value={value}
         name={name}
         disabled={disabled}
         onChange={inputOnChange}
         className={classNames(
           classes.input,
           { error: !!error },
           { password: type === 'password' }
         )}
       />
        { type === 'password' && !hideIcon &&
          <img
            src={ ShowIcon }
            alt="show"
            onClick={() => setInterType(TOOGLE_PASSWORD_TYPE[innerType])}
          />
        }
       <span className={classes.error}>{error}</span>
     </div>
  );
}

export default Input;
