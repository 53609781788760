import React from "react";
import {Brands} from './Brands';
import {Socials} from './Socials';

export const FooterSection = () => {
  return (
    <div className="brands__main-block main-block">
      <div className="brands-block__container _container">
        <div className="brands-block__body">
          <div className="brands-block__title">
            <h1>L'Oréal Active Cosmetics brands are:</h1>
          </div>
          <Brands />
          <Socials />
        </div>
        <div className="brands-block__image _ibg">
          <img src={require("../../img/white_texture_bg@3x.webp")} alt="cover" />
        </div>
      </div>
    </div>
  );
};
