import { CATEGORIES_ACTIONS, INITIAL_STATE } from '../constants';
import { sortSubcategories } from '../services/Helpers'

export default function categoriesReducer(state = INITIAL_STATE.CATEGORIES_STATE, action) {
  const { categories, subcategories, error, category_id, category, notifications } = action.payload || {};

  switch(action.type) {
    case CATEGORIES_ACTIONS.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories,
        error: null
      };
    case CATEGORIES_ACTIONS.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications,
      };
    case CATEGORIES_ACTIONS.GET_CATEGORIES_ERROR:
    case CATEGORIES_ACTIONS.GET_CATEGORY_ERROR:
    case CATEGORIES_ACTIONS.CREATE_CATEGORY_ERROR:
    case CATEGORIES_ACTIONS.EDIT_CATEGORY_ERROR:
      return {
        ...state,
        error,
        category: null,
        subcategories: null
      }
    case CATEGORIES_ACTIONS.GET_CATEGORY_SUCCESS: {
      const selectedCategory = state.categories.filter(category => Number(category_id) === Number(category.id));

      return {
        ...state,
        category: selectedCategory[0],
        subcategories: sortSubcategories(subcategories),
        error: null
      };
    }
    case CATEGORIES_ACTIONS.CHANGE_CATEGORY:
      return {
        ...state,
        subcategories: null,
        error: null
      };
    case CATEGORIES_ACTIONS.EDIT_CATEGORY_SUCCESS: {
      return {
        ...state,
        category,
        error: null
      };
    }
    case CATEGORIES_ACTIONS.CLEAR_CATEGORY: {
      return {
        ...state,
        category: null
      };
    }
    default:
      return state;
  }
};