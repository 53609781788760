import { takeEvery, put, call  } from 'redux-saga/effects';
import { LOREAL_STATISTIC_ACTIONS } from '../constants';
import { lorealStatisticActions } from '../actions';
import { processRequest } from '../services/Api';

// click: 0, view_consultation: 1, view_ads: 2, watch_video: 3, watch_consultation: 4, watch_ads: 5

//   якшо click то такий набір параметрів передавати
//   {
//   "statistic_type": 0,
//   "button_name":"еуие"
//   }

//   якшо view_page
//   {
//   "statistic_type": 1
//   }

//   якшо  watch_video
//   {
//   "statistic_type": 2,
//   "spend_time":100
//   }

//   якшо  watch_page
//   {
//   "statistic_type": 3,
//   "spend_time":100
//   }


const REQUEST_DATA_KEYS = {
  0: ['statistic_type', 'button_name'],
  1: ['statistic_type'],
  2: ['statistic_type'],
  3: ['statistic_type', 'spend_time'],
  4: ['statistic_type', 'spend_time'],
  5: ['statistic_type', 'spend_time'],
};

function* handleSetStatistic(action) {
  const {payload} = action || {};
  const {requestData} = payload || {};
  const {consultation_id, statistic_type} = requestData || {};
  try {
    let requestPayload = {};

    REQUEST_DATA_KEYS[statistic_type].forEach(key => {
      requestPayload = {
        ...requestPayload,
        [key]: requestData[key],
      };
    });

    // const requestPayload = {
    //   spend_time,
    //   statistic_type,
    // };
    const {data} = yield call(processRequest, `/consultations/${consultation_id}/set_statistic`, 'POST', requestPayload);
    if(data.message || data.consultation) {
      yield put(lorealStatisticActions.setStatisticSuccess());
    }
  } catch (e) {
    console.log(e);
    yield put(lorealStatisticActions.setStatisticError(e));
  }
}

export function* watchLorealStatisticSagas() {
  yield takeEvery(LOREAL_STATISTIC_ACTIONS.SET_STATISTIC, handleSetStatistic);
};