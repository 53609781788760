import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { globalActions, categoriesActions } from '../../actions';
import { Dashboard } from '../../components';

const mapStateToProps = state => {
  return {
    globalState: state.globalState,
    // categories: [
    //   { id: 1, title: 'Bfsdfds', subcategories_count: 1, subcategories: [{ id: 0, title: 'Bacteriology‎' }] },
    //   { id: 4, title: 'afffe', subcategories_count: 1, subcategories: [{ id: 0, title: 'anestesiology' }] },
    //   { id: 3, title: 'Ddfgs', subcategories_count: 1, subcategories: [{ id: 0, title: 'ALLERGY & IMMUNOLOGY' }] },
    //   { id: 0, title: 'Aasdfad', subcategories_count: 1, subcategories: [{ id: 0, title: 'anestesiology' }] },
    //   { id: 2, title: 'Csdf', subcategories_count: 1, subcategories: [{ id: 0, title: 'Addiction medicine' }] },
    // ]
    categories: state.categoriesState.categories,
    user: state.authState.user,
    notifications: state.categoriesState.notifications,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    globalActions: bindActionCreators(globalActions, dispatch),
    categoriesActions: bindActionCreators(categoriesActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);