import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import Modal from 'react-modal';
import MainWrapper from './containers/MainWrapper';
import { authActions } from './actions';
import './styles/scss/main.css';
import './styles/scss/contact_us.css';
import './styles/scss/media.css';
import store from './store';
import {
  SignIn,
  Home,
  Header,
  Footer,
  SignUp,
  SignUpConfirmation,
  ForgotPassword,
  ForgotPasswordConfirmation,
  NewPassword,
  ChangePassword,
  Dashboard,
  TermAndConditions,
  PrivacyPolicy,
  CookiesPolicy,
  ContactUs,
  Activity,
  Profile,
  CategoryDetail,
  ConsultEditCreate,
  ConsultationDetails,
  Reports,
  App,
  AboutUs,
  Updates,
  Loreal,
  Webinars,
  Nuggets,
} from './containers';
import { appHistory } from './services/HistoryConfig';
import { LOCAL_STORAGE, ROUTES } from './constants';

class Main extends Component {
  constructor() {
    super();

    this.state = {
      isOpen: false
    };
  }

  componentWillMount() {
    const { search, pathname } = window.location;
    const token = search.replace('?token=', '');
    const ua = navigator.userAgent;
    const is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

    if(is_ie){
      this.setState({ isOpen: true });
    }

    if(token && pathname !== '/new-password') {
      localStorage.setItem(LOCAL_STORAGE.AUTH_TOKEN, token);
      store.dispatch(authActions.requestUserData());
    }
  }

  checkUser = () => {
    const user = localStorage.getItem(LOCAL_STORAGE.USER);
    const token = localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN);
    const parsedUser = user ? JSON.parse(user) : null;

    return parsedUser || token;
  };

  renderStartPage = props => (
    this.checkUser() ? (
      <Dashboard { ...props } />
    ) : (
      <Home { ...props } />
    )
  );

  renderSignIn = props => (
    !this.checkUser() ? (
      <SignIn { ...props } />
    ) : (
      <Redirect to={ ROUTES.HOME } />
    )
  );

  renderSignUp = props => (
    !this.checkUser() ? (
      <SignUp { ...props } />
    ) : (
      <Redirect to={ ROUTES.HOME } />
    )
  );

  renderForgotPassword = props => (
    !this.checkUser() ? (
      <ForgotPassword { ...props } />
    ) : (
      <Redirect to={ ROUTES.HOME } />
    )
  );

  renderNewPassword = props => (
    !this.checkUser() ? (
      <NewPassword { ...props } />
    ) : (
      <Redirect to={ ROUTES.HOME } />
    )
  );

  renderChangePassword = props => (
    !this.checkUser() ? (
      <ChangePassword { ...props } />
    ) : (
      <Redirect to={ ROUTES.HOME } />
    )
  );

  renderSignUpConfirmation = props => (
    this.checkUser() ? (
      <SignUpConfirmation { ...props } />
    ) : (
      <Redirect to={ ROUTES.SIGN_IN } />
    )
  );

  renderActivity = props => (
    this.checkUser() ? (
      <Activity { ...props } />
    ) : (
      <Redirect to={ ROUTES.HOME } />
    )
  );

  renderProfile = props => (
    this.checkUser() ? (
      <Profile { ...props } />
    ) : (
      <Redirect to={ ROUTES.HOME } />
    )
  );

  renderConsultEditCreate = props => {
    const user = this.checkUser();

    return user && user.role === 'admin' ? (
      <ConsultEditCreate { ...props } />
    ) : (
      <Redirect to={ ROUTES.HOME } />
    )
  }

  renderCategoryDetail = props => {
    const user = this.checkUser();

    return user ? (
      <CategoryDetail { ...props } />
    ) : (
      <Redirect to={ ROUTES.HOME } />
    )
  }

  renderConsultationDetail = props => {
    const user = this.checkUser();

    return user && user.role !== 'admin' ? (
      <ConsultationDetails { ...props } />
    ) : (
      <Redirect to={ ROUTES.CONSULT_EDIT } />
    )
  }

  renderReports = props => {
    const user = this.checkUser();

    return user && user.role === 'admin' ? (
      <Reports { ...props } />
    ) : (
      <Redirect to={ ROUTES.HOME } />
    )
  }

  renderUpdates = props => {
    const user = this.checkUser();

    return user ? (
      <Updates { ...props } />
    ) : (
      <Redirect to={ROUTES.HOME} />
    );
  }

  renderLorealRoute = props => {
    const user = this.checkUser();

    return user ? (
      <Loreal { ...props } />
    ) : (
      <Redirect to={ROUTES.HOME} />
    )
  }

  renderNuggets = props => {
    const user = this.checkUser();

    return user ? (
      <Nuggets { ...props } />
    ) : (
      <Redirect to={ROUTES.HOME} />
    );
  }

  render() {
    const { isOpen } = this.state;

    return (
      <Provider store={ store }>
        <ConnectedRouter history={ appHistory }>
        <Modal
          isOpen={ isOpen }
          contentLabel="IEModal"
          className='modal'
          appElement={ document.getElementById('root') }
          style={{ overlay: { zIndex: 9999 } }}
        >
          <div className='modal-container' style={{ display: 'block', textAlign: 'center' }}>
            <span className='title' style={{ position: 'relative', top: '35%' }}>GPConsult does not work on this browser. Please use the Chrome, Firefox or Edge browsers for best results.</span>
          </div>
        </Modal>
          <Header />
          <MainWrapper>
            <App>
              <Switch>
                <Route
                  exact
                  path={ ROUTES.HOME }
                  render={ this.renderStartPage }
                />
                <Route
                  exact
                  name='SignIn'
                  path={ ROUTES.SIGN_IN }
                  render={ this.renderSignIn }
                />
                <Route
                  exact
                  name='SignUp'
                  path={ ROUTES.SIGN_UP }
                  render={ this.renderSignUp }
                />
                <Route
                  name='SignUpConfirmation'
                  path={ ROUTES.SIGN_UP_CONFIRMATION }
                  render={ this.renderSignUpConfirmation }
                />
                <Route
                  exact
                  name='ForgotPassword'
                  path={ ROUTES.FORGOT_PASSWORD }
                  render={ this.renderForgotPassword }
                />
                <Route
                  exact
                  name='ForgotPasswordConfirmation'
                  path={ ROUTES.FORGOT_PASSWORD_CONFIRMATION }
                  component={ ForgotPasswordConfirmation }
                />
                <Route
                  name='NewPassword'
                  path={ ROUTES.NEW_PASSWORD }
                  render={ this.renderNewPassword }
                />
                <Route
                  exact
                  name='ChangePassword'
                  path={ ROUTES.CHANGE_PASSWORD }
                  render={ this.renderChangePassword }
                />
                <Route
                  exact
                  name='TermAndConditions'
                  path={ ROUTES.TERMS_AND_CONDITIONS }
                  component={ TermAndConditions }
                />
                <Route
                  exact
                  name='PrivacyPolicy'
                  path={ ROUTES.PRIVACY_POLICY }
                  component={ PrivacyPolicy }
                />
                <Route
                  exact
                  name='ContactUs'
                  path={ ROUTES.CONTACT_US }
                  component={ ContactUs }
                />
                <Route
                  exact
                  name='Webinars'
                  path={ ROUTES.WEBINARS }
                  component={ Webinars }
                />
                <Route
                  exact
                  name='CookiesPolicy'
                  path={ ROUTES.COOKIES_POLICY }
                  component={ CookiesPolicy }
                />
                {/* <Route
                  exact
                  name='Activity'
                  path={ ROUTES.ACTIVITY }
                  render={ this.renderActivity }
                /> */}
                <Route
                  exact
                  name='Profile'
                  path={ ROUTES.PROFILE }
                  render={ this.renderProfile }
                />
                <Route
                  exact
                  name='CategoryDetail'
                  path={ ROUTES.CATEGORY_DETAIL }
                  render={ this.renderCategoryDetail }
                />
                <Route
                  exact
                  name='ConsultCreate'
                  path={ ROUTES.CONSULT_CREATE }
                  render={ this.renderConsultEditCreate }
                />
                <Route
                  exact
                  name='ConsultEdit'
                  path={ ROUTES.CONSULT_EDIT }
                  render={ this.renderConsultEditCreate }
                />
                <Route
                  exact
                  name='ConsultationDetails'
                  path={ ROUTES.CONSULTATION_DETAILS }
                  render={ this.renderConsultationDetail }
                />
                <Route
                  exact
                  name='Reports'
                  path={ ROUTES.REPORTS }
                  render={ this.renderReports }
                />
                <Route
                  exact
                  name='AboutUs'
                  path={ ROUTES.ABOUT_US }
                  component={ AboutUs }
                />
                <Route
                  exact
                  name='Updates'
                  path={ ROUTES.UPDATES }
                  render={ this.renderUpdates }
                />
                <Route
                  name='Loreal'
                  path={ ROUTES.LOREAL }
                  render={ this.renderLorealRoute }
                />
                <Route
                  exact
                  name='Nuggets'
                  path={ ROUTES.NUGGETS }
                  render={ this.renderNuggets }
                />
              </Switch>
              <Footer />
            </App>
          </MainWrapper>
        </ConnectedRouter>
      </Provider>
    );
  }
}

ReactDOM.render(
  <Main />,
  document.getElementById('root')
);
