import React from 'react';
import { AuthHeader } from '../../';
import Paul_Ryan from '../../../imgs/Paul_Ryan.jpeg';

const AboutUs = () => (
  <main>
    <AuthHeader
      title='About Us'
      description=''
    />
    <div className='container text-container'>
      <p>GPConsult.ie was founded by Dr. Paul Ryan and was launched in June 2019.</p>
      <p>It lists consultation templates on a range of different consultations seen in General Practice. These templates include the history and exam findings in common conditions seen in General Practice and where possible treatment suggestions as per recognised prescribing guidelines. They were reviewed by a team of Irish GPs working at the coal face of General Practice. They are being constantly updated using suggestions from GPs via the Comments section underneath each template.</p>
      <p>Paul is extremely grateful to all the GPs whose valuable contributions made this website possible.</p>
      <p><img src={ Paul_Ryan } alt='Paul_Ryan' /></p>
      <p>Dr Paul Ryan, GP and Pharmacist</p>
      <p>If you need to contact us for any reason, please email us at <a href='mailto:support@prescriptionrevision.ie'>support@prescriptionrevision.ie</a>. We would love to hear from you.</p>
    </div>
  </main>
);

export default AboutUs;