import React from "react";

export const Brands = () => {
  return (
    <div className="brands__column">
      <div className="brands__item item-brands">
        <div className="item-brands__icon">
          <img src={require("../../img/1-copy.svg")} alt="" />
        </div>
        <h3 className="item-brands__text">Allergy tested and tested on sensitive skin</h3>
      </div>
      <div className="brands__item item-brands">
        <div className="item-brands__icon">
          <img src={require("../../img/2-copy.svg")} alt="" />
        </div>
        <h3 className="item-brands__text">Developed with innovative science </h3>
      </div>
      <div className="brands__item item-brands">
        <div className="item-brands__icon">
          <img src={require("../../img/3-copy.svg")} alt="" />
        </div>
        <h3 className="item-brands__text">Use minimalist formulas</h3>
      </div>
      <div className="brands__item item-brands">
        <div className="item-brands__icon">
          <img src={require("../../img/4-copy.svg")} alt="" />
        </div>
        <h3 className="item-brands__text">Developed in partnership with dermatologists</h3>
      </div>
    </div>
  );
};
