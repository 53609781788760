import React, { Component } from 'react';
import moment from 'moment';
import {IMAGE_URL} from '../../../constants';
import '../../../styles/scss/webinars.css';

class Webinars extends Component {
  componentWillMount() {
    const { getWebinars, meta, globalActions: { toggleFooter }  } = this.props;

    toggleFooter(true);
    getWebinars(meta);
  }

  componentWillUnmount() {
    const { globalActions: { toggleFooter } } = this.props;

    toggleFooter(false);
  }

  loadMore = () => {
    const { getWebinars, meta } = this.props;

    getWebinars(({
      ...meta,
      page: meta.page + 1,
    }));
  };

  render() {
    const {webinars, meta} = this.props;
    return (
      <main className="page__webinars">
        <div className="webinars__block">
          <div className="webinars__page-container">
            <div className="webinars__page-content">
              <div className="webinars__desc">
                <h1>WEBINARS</h1>
                <span>We run webinars on a range of different topics.
                  These webinars have proven to be very popular with our members</span>
                  <p>If you are interested in working with us on a webinar,<b><a href='mailto:support@gpconsult.ie'> please contact us at support@gpconsult.ie</a></b> for more information.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="members__container">
          <div class="members__content">

            {webinars.map(webinar => (
              <div key={webinar.id} class="members__block">
                <div class="image-block">
                  <img src={`${IMAGE_URL}${webinar.logo_url}`} alt="" />
                </div>
                <div class="members-desc">
                  <p>{moment(webinar.start_date || new Date()).format('DD.MM.YYYY')}</p>
                  <span>{webinar.title || ''}</span>
                  <button class="view-webinar"><a href={webinar.url} target="_blank">View Webinar Snippet</a></button>
                </div>
              </div>
            ))}

            {meta.total_pages > meta.page ? (
              <button class='show-btn' onClick={this.loadMore}>Show More</button>
            ) : null}

          </div>
        </div>
      </main>
    )
  }
}

export default Webinars;