import { takeEvery, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { CATEGORIES_ACTIONS, ROUTES } from '../constants';
import { categoriesActions, authActions, globalActions } from '../actions';
import { processRequest } from '../services/Api';

function* handleGetCategories(action) {
  try {
    const { search } = action.payload;
    const searchValue = search ? search : '';
    const { data } = yield call(processRequest, `/categories?title=${ searchValue }`, 'GET');

    if(data.categories) {
      yield put(categoriesActions.getCategoriesSuccess(data.categories));
      if(data.notifications) {
        yield put(categoriesActions.getNotificationsSuccess(data.notifications));
      }
    } else if(data.error_message) {
      yield put(categoriesActions.getCategoriesError(data.error_message));
    }
  } catch(e) {
    const { response } = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(categoriesActions.getCategoriesError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
      yield put(replace(ROUTES.HOME));
    } else {
      yield put(categoriesActions.getCategoriesError('Internal server error.'));
    }
  }
}

function* handleGetCategory(action) {
  try {
    const { id, search } = action.payload;
    const searchValue = search ? search : '';

    const { data } = yield call(processRequest, `/categories/${ id }?title=${ searchValue }`, 'GET');

    if(data.subcategories.length >= 0) {
      yield put(categoriesActions.getCategorySuccess(id, data.subcategories));
    } else if(data.error_message) {
      yield put(categoriesActions.getCategoryError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(categoriesActions.getCategoryError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
      yield put(replace(ROUTES.HOME));
    } else {
      yield put(categoriesActions.getCategoryError('Internal server error.'));
    }
  }
}

function* handleCreateCategory(action) {
  try {
    const { title } = action.payload;
    const requestPayload = {
      category: { title }
    };

    const { data } = yield call(processRequest, `/categories`, 'POST', requestPayload);

    if(data.category) {
      yield put(categoriesActions.createCategorySuccess());
      yield put(categoriesActions.getCategories());
      yield put(globalActions.toggleAddCategoryModal(false));
      yield put(replace(ROUTES.HOME));
    } else if(data.error_message) {
      yield put(categoriesActions.createCategoryError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(categoriesActions.createCategoryError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
      yield put(replace(ROUTES.HOME));
    } else {
      yield put(categoriesActions.createCategoryError('Internal server error.'));
    }
  }
}

function* handleDeleteCategory(action) {
  try {
    const { category_id } = action.payload;
    const { data } = yield call(processRequest, `/categories/${ category_id }`, 'DELETE');

    if(data.message === 'Category deleted') {
      yield put(categoriesActions.deleteCategorySuccess());
      yield put(globalActions.toggleDeleteCategoryModal(false));
      yield put(replace(ROUTES.HOME));
    } else if(data.error_message) {
      yield put(categoriesActions.deleteCategoryError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(categoriesActions.deleteCategoryError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
      yield put(replace(ROUTES.HOME));
    } else {
      yield put(categoriesActions.deleteCategoryError('Internal server error.'));
    }
  }
}

function* handleEditCategory(action) {
  try {
    const { category_id, title } = action.payload;
    const requestPayload = {
      category: { title }
    };

    const { data } = yield call(processRequest, `/categories/${ category_id }`, 'PUT', requestPayload);

    if(data.category) {
      yield put(categoriesActions.getCategories());
      yield put(categoriesActions.editCategorySuccess(data.category));
      yield put(globalActions.toggleEditCategoryModal(false));
    } else if(data.error_message) {
      yield put(categoriesActions.createCategoryError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(categoriesActions.createCategoryError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
      yield put(replace(ROUTES.HOME));
    } else {
      yield put(categoriesActions.createCategoryError('Internal server error.'));
    }
  }
}

export function* watchCategoriesSagas() {
  yield takeEvery(CATEGORIES_ACTIONS.GET_CATEGORIES, handleGetCategories);
  yield takeEvery(CATEGORIES_ACTIONS.GET_CATEGORY, handleGetCategory);
  yield takeEvery(CATEGORIES_ACTIONS.CREATE_CATEGORY, handleCreateCategory);
  yield takeEvery(CATEGORIES_ACTIONS.DELETE_CATEGORY, handleDeleteCategory);
  yield takeEvery(CATEGORIES_ACTIONS.EDIT_CATEGORY, handleEditCategory);
};
