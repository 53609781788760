import { takeEvery, put, call  } from 'redux-saga/effects';
import { SUGGESTION_ACTIONS } from '../constants';
import { suggestionActions } from '../actions';
import { processRequest } from '../services/Api';

function* handleSendSuggestion(action) {
  const {payload} = action || {};
  const {text, consultation_id} = payload || {};
  try {
    const requestPayload = {
      title: "Suggestion",
      text,
      consultation_id,
    };
    const { data } = yield call(processRequest, '/suggestions', 'POST', requestPayload);
    if(data.message) {
      yield put(suggestionActions.sendSuggestionSuccess(data.message));
    }
  } catch (e) {
    console.log(e);
    yield put(suggestionActions.sendSuggestionError(e));
  }
}

export function* watchSuggestionSagas() {
  yield takeEvery(SUGGESTION_ACTIONS.SEND_SUGGESTION, handleSendSuggestion);
};