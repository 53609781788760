import React from 'react';
import { PlainInput } from '../../Inputs/Input';
import { isEmpty } from 'lodash';

export function AccountForm({ onChange, onSubmit, inputProps, formData, message, user }) {

  const onChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    onChange({ name, value: checked }, e);
  }

  return <>
   {/*
     <p>Account</p>
     */}
   <div className="tab tab-account">
     <form name='profile' onSubmit={onSubmit}>
       <PlainInput
         {...inputProps('first_name')}
         label="first name"
       />

       <PlainInput
        {...inputProps('last_name')}
        label={"surname"}
       />

      <PlainInput
        {...inputProps('email')}
        type={'email'}
        label={'email'}
        disabled={ user.role !== 'admin' }
      />
       <div className="notification-item">
         <div className="checkbox-block">
           <input
             className="styled-checkbox"
             id="styled-checkbox-1"
             type="checkbox"
             value="value1"
             name="email_notifications"
             checked={formData.email_notifications}
             onChange={onChangeCheckbox}
           />
           <label for="styled-checkbox-1">
             <span> Send me an email notification when someone replies to my comment</span>
           </label>
         </div>
       </div>
       { message && <p className='profile-updated-success-message'>{ message }</p> }
       <button className='btn large filled submit'>update</button>
     </form>
   </div>
  </>
};

AccountForm.fields = ['first_name', 'last_name', 'email', 'email_notifications'];
AccountForm.validate = (formData) => {
  const { first_name, last_name, email } = formData;
  const errors = {};

  if (!first_name) {
    errors.first_name = ['Please enter your first name.'];
  }
  if (!last_name) {
    errors.last_name = ['Please enter your surname name.'];
  }
  if (!email) {
    errors.email = ['Please enter your email.'];
  }

  if (isEmpty(errors)) {
    return false;
  }

  return errors;
}
