import React, { Component } from 'react';
import _ from 'lodash';
import Modal from 'react-modal';
import { DISCLAIMER, IMAGE_URL } from '../../../constants';
import { TitleInputWithEditor, InputWithEditor, MenuCross, AuthenticationButton, CommentsSection, Input, FormContainer } from '../../';
import BackArrow from '../../../imgs/back-arrow.svg';
import CloseIcon from '../../../imgs/close-icon.svg';
import 'react-quill/dist/quill.snow.css';

class ConsultEditCreate extends Component {
  constructor(props) {
    super(props);

    const consultation = props.consultation;

    this.state = {
      consultation: !_.isEmpty(consultation) ? {
        ...consultation,
        consultationAttachments: consultation.attachments,
        consultationAttachmentsToDisplay: consultation.attachments,
        removedAttachmentsIds: []
      } : {
        history: '',
        exam: '',
        impresion: '',
        plan: '',
        title: '',
        aditional_info: '',
        disclaimer: DISCLAIMER,
        sponsor_title: '',
        sponsor_logo_url: '',
        subcategory: '',
        sponsor_url: '',
        consultationAttachments: [],
        consultationAttachmentsToDisplay: [],
        removedAttachmentsIds: []
      },
      isAnotherInputFocused: false,
      sponsor: null,
      titleForSend: '',
      subcategoryForSend: '',
      showModal: false,
      activeCommentPlaceholder: false,
      attachments: [],
      attachmentsToDisplay: [],
      body: '',
      error: ''
    };
  }

  componentWillMount() {
    const { consultationsActions, match: { params } } = this.props;

    if(params.id) {
      consultationsActions.getConsultation(params.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { consultation } = this.props;

    if(consultation !== nextProps.consultation) {
      this.setState({
        consultation: {
          ...nextProps.consultation,
          consultationAttachments: nextProps.consultation.attachments,
          consultationAttachmentsToDisplay: nextProps.consultation.attachments,
          removedAttachmentsIds: []
        },
        titleForSend: nextProps.consultation.title,
        subcategoryForSend: nextProps.consultation.subcategory
      });
    }
    if(nextProps.successMessage) {
      setTimeout(this.props.commentsActions.clearSuccessMessage, 2000);
    }
  }

  componentWillUnmount() {
    this.props.consultationsActions.clearConsultation();
  }

  openModal = () => {
    this.setState({ showModal: true });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  }

  changeTitle = title => {
    this.setState(({ consultation }) => ({
      consultation: {
        ...consultation,
        title: title.getHTML()
      },
      titleForSend: title.getText()
    }));
  }

  historyChange = history => {
    this.setState(({ consultation }) => ({
      consultation: {
        ...consultation,
        history
      }
    }));
  }

  examChange = exam => {
    this.setState(({ consultation }) => ({
      consultation: {
        ...consultation,
        exam
      }
    }));
  }

  impressionChange = impresion => {
    this.setState(({ consultation }) => ({
      consultation: {
        ...consultation,
        impresion
      }
    }));
  }

  planChange = plan => {
    this.setState(({ consultation }) => ({
      consultation: {
        ...consultation,
        plan
      }
    }));
  }

  aditionalInfoChange = aditional_info => {
    this.setState(({ consultation }) => ({
      consultation: {
        ...consultation,
        aditional_info
      }
    }));
  }

  disclaimerChange = disclaimer => {
    this.setState(({ consultation }) => ({
      consultation: {
        ...consultation,
        disclaimer
      }
    }));
  }

  subcategoryChange = subcategory => {
    this.setState(({ consultation }) => ({
      consultation: {
        ...consultation,
        subcategory: subcategory.getHTML()
      },
      subcategoryForSend: subcategory.getText()
    }));
  }

  onFocus = name => {
    this.setState({ isAnotherInputFocused: name });
  }

  onBlur = () => {
    this.setState({ isAnotherInputFocused: false });
  }

  addSponsor = event => {
    const sponsor = event.target.files;

    this.setState((prevState) => ({
      sponsor: sponsor[0],
      consultation: {
        ...prevState.consultation,
          sponsor_title: sponsor[0].name.substring(0, sponsor[0].name.length - 4),
          sponsor_logo_url: URL.createObjectURL(sponsor[0]),
          is_sponsored: true
        }
    }));
  }

  removeSponsor = () => {
    this.setState(({ consultation }) => ({
      sponsor: {},
      consultation: {
        ...consultation,
        sponsor_title: '',
        sponsor_logo_url: '',
        is_sponsored: false
      },
      showModal: false
    }));
  }

  onCancel = () => {
    this.props.history.goBack();
  }

  onFocusComment = () => {
    this.setState({ activeCommentPlaceholder: true });
  }

  onHideDisclaimer = () => {
    this.setState({ hideDisclaimer: true });
  }

  onWriteComment = event => {
    const body = event.target.value;

    this.setState({ body });
  }

  addAttachment = event => {
    const attachment = event.target.files;
    const attachmentFile = {
      name: attachment[0].name,
      attachment_content_type: attachment[0].type,
      attachment_file_size: attachment[0].size
    };

    this.setState((prevState) => ({
      ...prevState,
      attachments: [...prevState.attachments, attachment[0]],
      attachmentsToDisplay: [...prevState.attachmentsToDisplay, attachmentFile]
    }));
  }

  addConsultationAttachment = event => {
    const attachment = event.target.files;
    const section = event.target.id;
    const attachmentFile = {
      name: attachment[0].name,
      attachment_content_type: attachment[0].type,
      attachment_file_size: attachment[0].size,
      section,
    };
    const attachmentForSend = {
      attachment: attachment[0],
      section
    }

    this.setState((prevState) => ({
      ...prevState,
      consultation: {
        ...prevState.consultation,
        consultationAttachments: [...prevState.consultation.consultationAttachments, attachmentForSend],
        consultationAttachmentsToDisplay: [...prevState.consultation.consultationAttachmentsToDisplay, attachmentFile]
      }
    }));
  }

  removeAttachment = index => {
    const prevAttachments = this.state.attachments;
    prevAttachments.splice(index, 1);
    const prevAttachmentsToDisplay = this.state.attachmentsToDisplay;
    prevAttachmentsToDisplay.splice(index, 1);

    this.setState((prevState) => ({
      ...prevState,
      attachments: prevAttachments,
      attachmentsToDisplay: prevAttachmentsToDisplay
    }));
  }

  removeConsultationAttachment = index => {
    const prevAttachments = this.state.consultation.consultationAttachments;
    const removedAttachmentsId = prevAttachments.splice(index, 1)[0].id;
    const filterIds = typeof removedAttachmentsId === 'number' ? removedAttachmentsId : false;
    const prevAttachmentsToDisplay = this.state.consultation.consultationAttachmentsToDisplay;
    prevAttachmentsToDisplay.splice(index, 1);

    this.setState((prevState) => ({
      ...prevState,
      consultation: {
        ...prevState.consultation,
        consultationAttachments: prevAttachments,
        consultationAttachmentsToDisplay: prevAttachmentsToDisplay,
        removedAttachmentsIds: [...prevState.consultation.removedAttachmentsIds, filterIds]
      }
    }));
  }

  isValidUrl = url => {
    try {
      new URL(url);
      return url;
    } catch (_) {
      return `https://${ url }`;
    }
  }

  onSponsorUrlChange = event => {
    const sponsor_url = event.target.value;

    this.setState(({ consultation }) => ({
      consultation: {
        ...consultation,
        sponsor_url
      }
    }));
  }

  addComment = () => {
    const { body, attachments } = this.state;
    const { commentsActions, match: { params } } = this.props;
    const commentData = {
      consultation_id: params.id,
      body,
      attachments
    };

    if(!body || body === '') {
      this.setState({ error: 'Comment can`t be blank.' });
    } else {
      commentsActions.addComment(commentData);
      this.setState({ body: '', attachments: [], activeCommentPlaceholder: false, attachmentsToDisplay: [], error: '' });
    }
  }

  addReply = comment_id => {
    const { commentsActions, match: { params } } = this.props;
    const { body, attachments } = this.state;
    const replyData = {
      comment_id,
      body,
      consultation_id: params.id,
      attachments
    };

    if(!body || body === '') {
      this.setState({ error: 'Reply can`t be blank.' });
    } else {
      commentsActions.addReply(replyData);
      this.setState({ body: '', attachments: [], activeCommentPlaceholder: false, attachmentsToDisplay: [], error: '' });
    }
  }

  onLike = (id, type) => {
    const { commentsActions, match: { params } } = this.props;

    if(type === 'comment') {
      commentsActions.likeForComment(id, params.id);
    } else {
      commentsActions.likeForReply(id, params.id);
    }
  }

  hideCommentInput = () => {
    this.setState({ activeCommentPlaceholder: false, error: '' });
  }

  openEditCommentReplyModal = (commentReply, type) => {
    this.props.globalActions.toggleEditCommentReplyModal(true, commentReply, type);
  }

  onDeleteCommentReply = (commentReply, type) => {
    this.props.globalActions.toggleDeleteCommentReplyModal(true, commentReply, type);
  }

  clearError = () => {
    this.setState({ error: '' });
  }

  saveConsult = () => {
    const { consultationsActions, consultation, category } = this.props;
    const {
      sponsor,
      subcategoryForSend,
      consultation: {
        subcategory,
        history,
        exam,
        impresion,
        plan,
        sponsor_title,
        aditional_info,
        disclaimer,
        sponsor_url,
        consultationAttachments,
        removedAttachmentsIds
      }
    } = this.state;
    const newConsultation = {
      consultation_id: (consultation && consultation.id) || null,
      category_id: (consultation && consultation.category && consultation.category.id) || category.id,
      subcategory: subcategoryForSend,
      disclaimer,
      title: subcategory,
      history: history ? history : '',
      exam: exam ? exam : '',
      impresion: impresion ? impresion : '',
      plan: plan ? plan : '',
      is_sponsored: !!sponsor_title,
      sponsor_title,
      sponsor_logo: sponsor,
      aditional_info: aditional_info ? aditional_info : '',
      sponsor_url: sponsor_url ? this.isValidUrl(sponsor_url) : '',
      attachments: consultationAttachments.length > 0 ? consultationAttachments : (consultation && consultation.attachments ? consultation.attachments : null),
      removedAttachmentsIds
    };

    if(consultation && consultation.id ) {
      consultationsActions.editConsultation(newConsultation);
    } else {
      consultationsActions.saveConsultation(newConsultation);
    }
  }

  showMoreReplies = comment_id => {
    this.props.commentsActions.getComment(comment_id);
  }

  render() {
    const {
      isAnotherInputFocused,
      consultation: {
        history,
        exam,
        impresion,
        plan,
        sponsor_title,
        sponsor_logo_url,
        sponsor_url,
        aditional_info,
        disclaimer,
        subcategory,
        is_sponsored,
        consultationAttachmentsToDisplay,
      },
      showModal,
      activeCommentPlaceholder,
      attachmentsToDisplay,
      body
    } = this.state;
    const { consultation, error, category, user, successMessage, comment } = this.props;

    return (
      <main>
         <Modal
          isOpen={ showModal }
          onRequestClose={ this.closeModal }
          contentLabel="AddCategoryModal"
          className='modal'
          appElement={ document.getElementById('app') }
        >
          <div className='modal-container'>
            <span className='title'>Are you sure you want to delete this sponsor?</span>
            <button
              className="modal-close-btn"
              tabIndex="1"
              onClick={ this.closeModal }
            >
              { MenuCross }
            </button>
            <AuthenticationButton
              title='Delete'
              onClick={ this.removeSponsor }
            />
          </div>
        </Modal>
        <section className="sec-consult-main">
          <div className="container">
            <div className="navigation-back sponsor">
              <button
                className='btn'
                onClick={ this.props.history.goBack }
              >
                <div className="back-container">
                  <div className="back-arrow">
                    <img src={ BackArrow } alt="Back arrow" />
                  </div>
                  <div className="back-text">
                    <p className="blue">
                      { consultation ?
                        consultation.category ? consultation.category.title : '' :
                        (category ? category.title : '')
                      }
                    </p>
                  </div>
                </div>
              </button>
              <div className="add-sponsor">
                { is_sponsored ?
                  <div className="result-spon">
                    <button onClick={ this.openModal }>
                      <img src={ CloseIcon } alt="close btn"/>
                    </button>
                    <div className="navigation-sponsor">
                      {/* <div className='sponsor-title'>
                        <p>{ 'Kindly supported by: ' }</p>
                      </div> */}
                      <div className='support-logo'>
                        <img src={ sponsor_logo_url.match('blob') ? sponsor_logo_url : `${ IMAGE_URL }${ sponsor_logo_url }` } alt="sponsor logo" />
                      </div>
                      <FormContainer classStyle='' style={{ marginTop: 0 }}>
                        <Input
                          onInputChange={ this.onSponsorUrlChange }
                          value={ sponsor_url }
                          type='text'
                          placeholder='Enter sponsor url'
                          hideIcon
                        />
                      </FormContainer>
                    </div>
                  </div> :
                  <button className="btn outlined small">
                    <input
                      id='add-sp-file'
                      type='file'
                      style={{ display: 'none' }}
                      className="like-text"
                      onChange={ this.addSponsor }
                    />
                    <label htmlFor='add-sp-file'>add sponsor</label>
                  </button>
                }
              </div>
            </div>
          <div className="consult-container-editor">
            { error &&
              <span className='main-auth-error consult-error'>{ error }</span>
            }
            <div className="container-small">
              <TitleInputWithEditor
                title='Subcategory'
                value={ subcategory }
                className='subcategory'
                onChange={ this.subcategoryChange }
                placeholder='Enter subcategory'
                isAnotherInputFocused={ isAnotherInputFocused }
                onFocus={ this.onFocus }
                onBlur={ this.onBlur }
                name='subcategory'
                disableToggle
              />
              { (sponsor_title || sponsor_logo_url) &&
                <a href={ sponsor_logo_url.match('blob') ? sponsor_logo_url : `${ IMAGE_URL }${ sponsor_logo_url }` } target='_blank' rel="noopener noreferrer">
                  <div className="navigation-sponsor">
                    <div className='support-logo'>
                      <img src={ sponsor_logo_url.match('blob') ? sponsor_logo_url : `${ IMAGE_URL }${ sponsor_logo_url }` } alt="sponsor logo"/>
                    </div>
                    {/* <div className='sponsor-title'>
                      <p>{ 'Supported by: ' }</p>
                    </div> */}
                  </div>
                </a>
              }
              <InputWithEditor
                title='History'
                value={ history }
                className='history'
                onChange={ this.historyChange }
                placeholder='Enter history'
                isAnotherInputFocused={ isAnotherInputFocused }
                onFocus={ this.onFocus }
                onBlur={ this.onBlur }
                name='history'
                consultationAttachmentsToDisplay={consultationAttachmentsToDisplay}
                addConsultationAttachment={this.addConsultationAttachment}
                removeConsultationAttachment={this.removeConsultationAttachment}
              />
              <InputWithEditor
                title='Exam'
                value={ exam }
                className='exam'
                onChange={ this.examChange }
                placeholder='Enter exam'
                isAnotherInputFocused={ isAnotherInputFocused }
                onFocus={ this.onFocus }
                onBlur={ this.onBlur }
                name='exam'
                consultationAttachmentsToDisplay={consultationAttachmentsToDisplay}
                addConsultationAttachment={this.addConsultationAttachment}
                removeConsultationAttachment={this.removeConsultationAttachment}
              />
              <InputWithEditor
                title='Impression'
                value={ impresion }
                className='impression'
                onChange={ this.impressionChange }
                placeholder='Enter impression'
                isAnotherInputFocused={ isAnotherInputFocused }
                onFocus={ this.onFocus }
                onBlur={ this.onBlur }
                name='impression'
                consultationAttachmentsToDisplay={consultationAttachmentsToDisplay}
                addConsultationAttachment={this.addConsultationAttachment}
                removeConsultationAttachment={this.removeConsultationAttachment}
              />
              <InputWithEditor
                title='Plan'
                value={ plan }
                className='plan'
                onChange={ this.planChange }
                placeholder='Enter plan'
                isAnotherInputFocused={ isAnotherInputFocused }
                onFocus={ this.onFocus }
                onBlur={ this.onBlur }
                name='plan'
                consultationAttachmentsToDisplay={consultationAttachmentsToDisplay}
                addConsultationAttachment={this.addConsultationAttachment}
                removeConsultationAttachment={this.removeConsultationAttachment}
              />
              <InputWithEditor
                title='Additional Info'
                value={ aditional_info }
                className='aditional_info'
                onChange={ this.aditionalInfoChange }
                placeholder='Enter additional info'
                isAnotherInputFocused={ isAnotherInputFocused }
                onFocus={ this.onFocus }
                onBlur={ this.onBlur }
                name='aditional_info'
                consultationAttachmentsToDisplay={consultationAttachmentsToDisplay}
                addConsultationAttachment={this.addConsultationAttachment}
                removeConsultationAttachment={this.removeConsultationAttachment}
              />
              <InputWithEditor
                title='Disclaimer'
                value={ disclaimer }
                className='disclaimer'
                onChange={ this.disclaimerChange }
                placeholder='Enter disclaimer'
                isAnotherInputFocused={ isAnotherInputFocused }
                onFocus={ this.onFocus }
                onBlur={ this.onBlur }
                name='disclaimer'
                disableToggle
              />
              </div>
            </div>
          </div>
          {/* <CommentsSection
            comments={ (consultation && consultation.comments) || [] }
            comments_count={ (consultation && consultation.comments_count) || 0 }
            onFocusComment={ this.onFocusComment }
            activeCommentPlaceholder={ activeCommentPlaceholder }
            addAttachment={ this.addAttachment }
            removeAttachment={ this.removeAttachment }
            attachments={ attachmentsToDisplay }
            body={ body }
            onWriteComment={ this.onWriteComment }
            addComment={ this.addComment }
            user={ user }
            onLike={ this.onLike }
            addReply={ this.addReply }
            hideCommentInput={ this.hideCommentInput }
            openEditCommentReplyModal={ this.openEditCommentReplyModal }
            onDeleteCommentReply={ this.onDeleteCommentReply }
            clearError={ this.clearError }
            error={ error }
            successMessage={ successMessage }
            showMoreReplies={ this.showMoreReplies }
            commentWithAllReplies={ comment }
          /> */}
          <div className="consult-controls">
            <div className="container">
              <div className="controls-wrapper">
                <button onClick={ this.saveConsult } className='btn filled large '>confirm changes</button>
                <button onClick={ this.onCancel } className='btn outlined large'>cancel</button>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default ConsultEditCreate;