import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { ROUTES, IMAGE_URL } from '../../../constants';
import pdf from '../../../imgs/pdf.svg';
import update from '../../../imgs/update.svg';

const Updates = ({value, user}) => {
  const {id, title, updated_at, pdf_updated, pdf_url} = value || {};
  return (
    <div className="list-item">
      <div className="item-ava">
        <div className="user-avatar avatar" style={{width: '50%', height: '50%', padding: '4px'}}>
          <div className="user-avatar">
            <img src={pdf_updated ? pdf : update} alt="update" />
          </div>
        </div>
      </div>

      {pdf_updated ?
        <div className="item-profile">
          <div className="item-profile--user">
            <a
              href={`${IMAGE_URL}/${pdf_url}`}
              target="_blank"
              style={{color: '#1177ee', fontWeight: 'bold', fontSize: '16px'}}
            >
              {ReactHtmlParser(title)}
            </a>
            <span style={{color: '#2e3c4c', fontSize: '14px'}}>Pharmacology Nugget has been added</span>
          </div>
          <div className="item-activity">
            <div className="item-activity--text">
              <span style={{color: '#2e3c4c', fontWeight: 'normal', fontSize: '14px'}}>{moment(updated_at).format('DD/MM/YYYY')}</span>
            </div>
          </div>
        </div> :
        <div className="item-profile">
          <div className="item-profile--user">
            <Link
              to={ user && user.role !== 'admin' ?
                `${ ROUTES.CONSULTATION_DETAILS.replace('/:id', '') }/${ id }` :
                `${ ROUTES.CONSULT_EDIT.replace('/:id', '') }/${ id }`
              }
              style={{color: '#1177ee', fontWeight: 'bold', fontSize: '16px'}}
            >
              {ReactHtmlParser(title)}
            </Link>
            <span style={{color: '#2e3c4c', fontSize: '14px'}}>has been updated</span>
          </div>
          <div className="item-activity">
            <div className="item-activity--text">
              <span style={{color: '#2e3c4c', fontWeight: 'normal', fontSize: '14px'}}>{moment(updated_at).format('DD/MM/YYYY')}</span>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Updates;
