import React from "react";

export const Service = ({item, setSelected}) => {
  const handleItemClick = () => {
    setSelected(item);
  };

  return (
    <div className="services__item item-service" onClick={handleItemClick}>
      <div id="item-one" className="item-service__icon">
        <img src={item.imageUrl} alt="" />
      </div>
      <h3 className="item-service__text">{item.title}</h3>
    </div>
  );
};
