import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConsultationDetails } from '../../components';
import {
  consultationsActions,
  commentsActions,
  globalActions,
  suggestionActions,
  lorealStatisticActions,
} from '../../actions';

const mapStateToProps = state => {
  return {
    consultation: state.consultationsState.consultation,
    sponsor: state.consultationsState.sponsor,
    category: state.categoriesState.category,
    user: state.authState.user,
    successMessage: state.commentsState.successMessage,
    comment: state.commentsState.comment,
    suggestionSentSuccess: state.suggestionState.suggestionSentSuccess,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    consultationsActions: bindActionCreators(consultationsActions, dispatch),
    commentsActions: bindActionCreators(commentsActions, dispatch),
    globalActions: bindActionCreators(globalActions, dispatch),
    sendSuggestion: bindActionCreators(suggestionActions.sendSuggestion, dispatch),
    clearSuccessSuggestionMessage: bindActionCreators(suggestionActions.clearSuccessMessage, dispatch),
    setStatistic: bindActionCreators(lorealStatisticActions.setStatistic, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationDetails);