import {WEBINARS_ACTIONS, INITIAL_STATE} from '../constants';

const webinarsReducer = (state = INITIAL_STATE.WEBINARS_STATE, action) => {
  const {type, payload} = action || {};
  const {meta, webinars, total_pages, error} = payload || {};

  switch (type) {
    case WEBINARS_ACTIONS.GET_WEBINARS:
      return {
        ...state,
        meta,
        error: null,
      };
    case WEBINARS_ACTIONS.GET_WEBINARS_SUCCESS:
      return {
        ...state,
        webinars,
        meta: {
          ...state.meta,
          total_pages: total_pages,
        },
      };
    case WEBINARS_ACTIONS.GET_WEBINARS_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        webinars: [...state.webinars, ...webinars],
        meta: {
          ...state.meta,
          total_pages: total_pages,
        },
      };
    case WEBINARS_ACTIONS.GET_WEBINARS_ERROR:
      return {
        ...state,
        error,
      };
    default:
      return state;
  };
};

export default webinarsReducer;
