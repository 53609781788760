import AUTH_ACTIONS from './actions/authActions';
import GLOBAL_ACTIONS from './actions/globalActions';
import CATEGORIES_ACTIONS from './actions/categoriesActions';
import CONSULTATIONS_ACTIONS from './actions/consultationsActions';
import ACTIVITY_ACTIONS from './actions/activityActions';
import ADDITOONAL_DATA_ACTIONS from './actions/additionalDataActions';
import COMMENTS_ACTIONS from './actions/commentsActions';
import CURRENT_USER_ACTIONS from './actions/currentUserActions';
import REPORTS_ACTIONS from './actions/reportsActions';
import SUGGESTION_ACTIONS from './actions/suggestionActions';
import WEBINARS_ACTIONS from './actions/webinarsActions';
import CONTACT_US_ACTIONS from './actions/contactUsActions';
import LOREAL_STATISTIC_ACTIONS from './actions/lorealStatisticActions';
import NUGGETS_ACTIONS from './actions/nuggetsActions';
import DATE_CONSTANTS from './Date';
import LOCAL_STORAGE from './LocalStorage';
import INITIAL_STATE from './InitialState';
import ROUTES from './Routes';
import API_URL from './ApiUrls.json';
import {LOREAL_TYPES} from './LorealTypes';
import {LOREAL_ITEMS} from './Lorealtems';

const ENV = 'prod'; // local, dev, prod

export const BASE_API_URL = API_URL[ENV].URL;
export const IMAGE_URL = BASE_API_URL.replace('/api/v1', '');
export const EMAIL = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,})/;
export const COUNTIES = [
  { label: 'County', value: '' },
  { label: 'Antrim', value: 0 },
  { label: 'Armagh', value: 1},
  { label: 'Carlow', value: 2},
  { label: 'Cavan', value: 3},
  { label: 'Clare', value: 4},
  { label: 'Cork', value: 5},
  { label: 'Derry', value: 6},
  { label: 'Donegal', value: 7},
  { label: 'Down', value: 8},
  { label: 'Dublin North', value: 9},
  { label: 'Dublin South', value: 33},
  { label: 'Fermanagh', value: 10},
  { label: 'Galway', value: 11},
  { label: 'Kerry', value: 12},
  { label: 'Kildare', value: 13},
  { label: 'Kilkenny', value: 14},
  { label: 'Laois', value: 15},
  { label: 'Leitrim', value: 16},
  { label: 'Limerick', value: 17},
  { label: 'Longford', value: 18},
  { label: 'Louth', value: 19},
  { label: 'Mayo', value: 20},
  { label: 'Meath', value: 21},
  { label: 'Monaghan', value: 22},
  { label: 'Offaly', value: 23},
  { label: 'Roscommon', value: 24},
  { label: 'Sligo', value: 25},
  { label: 'Tipperary', value: 26},
  { label: 'Tyrone', value: 27},
  { label: 'Waterford', value: 28},
  { label: 'Westmeath', value: 29},
  { label: 'Wexford', value: 30},
  { label: 'Wicklow', value: 31}
];
export const GP_TYPE = [
  { label: 'GP Type', value: '' },
  { label: 'GP Principal', value: 0 },
  { label: 'Salaried GP', value: 1 },
  { label: 'Locum', value: 2 },
  { label: 'Other', value: 3 }
];

export const GP_TYPES = {
  gp_principal: 'Principal',
  salaried_gp: 'Salaried GP',
  locum: 'Locum',
  other: 'Other',
}

export const USER_TYPE = [
  { label: 'I am a:', value: '' },
  { label: 'GP', value: 0 },
  { label: 'GP Trainee', value: 1 },
  { label: 'Practice Nurse', value: 2 }
];

export const USER_TYPES = {
  gp: 'GP',
  gp_trainee: 'GP Trainee',
  practice_nurse: 'Practice Nurse'
}

export const modules = {
  toolbar: [
    [{ 'color': ['#2E3C4C','#565A5E'] }],
    ['bold', 'italic', 'underline'],
    ['link', 'image'],
    [{ 'size': ['small', false, 'large'] }],
    ['clean']
  ]
};

export const formats = [
  'color',
  'size',
  'bold',
  'italic',
  'underline',
  'link',
  'image'
];

export const titleModules = {
  toolbar: [
    [{ 'color': ['#2E3C4C','#565A5E'] }],
    ['bold', 'italic', 'underline'],
    [{ 'size': ['small', false, 'large'] }],
    ['clean']
  ]
};

export const titleFormats = [
  'color',
  'size',
  'bold',
  'italic',
  'underline',
];

export const DISCLAIMER = `Under no circumstances should anything stated below override the individual responsibility of healthcare professionals to make decisions appropriate to the circumstances of individual patients, inconsultation with the patient and/or guardian or carer.`

export {
  AUTH_ACTIONS,
  GLOBAL_ACTIONS,
  CATEGORIES_ACTIONS,
  CONSULTATIONS_ACTIONS,
  COMMENTS_ACTIONS,
  WEBINARS_ACTIONS,
  CONTACT_US_ACTIONS,
  DATE_CONSTANTS,
  LOCAL_STORAGE,
  INITIAL_STATE,
  ROUTES,
  ACTIVITY_ACTIONS,
  ADDITOONAL_DATA_ACTIONS,
  CURRENT_USER_ACTIONS,
  REPORTS_ACTIONS,
  SUGGESTION_ACTIONS,
  LOREAL_STATISTIC_ACTIONS,
  NUGGETS_ACTIONS,
  LOREAL_TYPES,
  LOREAL_ITEMS,
};
