export default {
  HOME: '/',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  SIGN_UP_CONFIRMATION: '/sign-up-confirmation',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_CONFIRMATION: '/forgot-password-confirmation',
  NEW_PASSWORD: '/new-password',
  CHANGE_PASSWORD: '/change-password',
  CONTACT_US: '/contact-us',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/provacy-policy',
  COOKIES_POLICY: '/cookies-policy',
  ACTIVITY: '/activity',
  PROFILE: '/profile',
  CONSULT_CREATE: '/consult-create',
  CONSULT_EDIT: '/consult-edit/:id',
  CATEGORY_DETAIL: '/category-details/:id',
  CONSULTATION_DETAILS: '/consultation-details/:id',
  REPORTS: '/reports',
  ABOUT_US: '/about-us',
  UPDATES: '/updates',
  LOREAL: '/loreal/:title?',
  WEBINARS: '/webinars',
  NUGGETS: '/nuggets',
}