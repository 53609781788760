import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { globalActions, contactUsActions } from '../../actions';
import { ContactUs } from '../../components';

const mapStateToProps = state => {
  return {
    globalState: state.globalState
  };
}

const mapDispatchToProps = dispatch => {
  return {
    globalActions: bindActionCreators(globalActions, dispatch),
    sendMail: bindActionCreators(contactUsActions.sendMail, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);