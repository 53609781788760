import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import LogoCompact from '../../../imgs/GPC_vector.svg';
import LogoBigOne from '../../../imgs/GPC_BigOne.png';
import LogoOneX from '../../../imgs/GPC_x1.png';
import LogoThreeX from '../../../imgs/GPC_x3.png';

const LogoButton = ({ toggleMenu }) => (
  <Link to={ ROUTES.HOME } onClick={ toggleMenu } style={{ flexBasis: '260px' }}>
    <div className="logo-block">
      <picture>
        <source media="(max-width: 575px)" srcSet={ LogoCompact } />
        <source media="(min-width: 576px)" srcSet={ LogoCompact } />
        <source media="(mix-width: 9999px)" srcSet={ LogoBigOne } />
        <source media="(min-width: 9997px)" srcSet={ LogoOneX } />
        <source media="(mix-width: 9998px)" srcSet={ LogoThreeX } />
        <img src={ LogoCompact } alt="GPC Logo" />
      </picture>
    </div>
  </Link>
);

export default LogoButton;