import {WEBINARS_ACTIONS} from '../constants';

export const getWebinars = meta => ({
  type: WEBINARS_ACTIONS.GET_WEBINARS,
  payload: {meta},
});

export const getWebinarsSuccess = (webinars, total_pages) => ({
  type: WEBINARS_ACTIONS.GET_WEBINARS_SUCCESS,
  payload: {webinars, total_pages},
});

export const getWebinarsNextPageSuccess = (webinars, total_pages) => ({
  type: WEBINARS_ACTIONS.GET_WEBINARS_NEXT_PAGE_SUCCESS,
  payload: {webinars, total_pages},
});

export const getWebinarsError = error => ({
  type: WEBINARS_ACTIONS.GET_WEBINARS_ERROR,
  payload: {error},
});
