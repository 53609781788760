import React, {useEffect, useState} from 'react';
import {
  TopSection,
  Services,
  Recommends,
  Sponsors,
  FooterSection,
} from './components';

export const Loreal = ({
  title,
  description,
  imageUrl,
  items,
  showRecommends,
  defaultActive,
  consultationId,
  setStatistic,
}) => {
  const [spendTime, setSpendTime] = useState(0);
  const [statisticSent, setStatisticSent] = useState(false);
  const updateTimer = () => {
    setSpendTime(spendTime => spendTime + 1);
  };
  const handleButtonClick = buttonName => {
    setStatistic({
      consultation_id: consultationId,
      statistic_type: 0,
      button_name: buttonName,
    });
  };
  const handleSendStatistic = () => {
    if (!statisticSent) {
      setStatisticSent(true);
      setStatistic({
        consultation_id: consultationId,
        spend_time: spendTime,
        statistic_type: 5,
      });
    }
  };

  useEffect(() => {
    const timer = setInterval(updateTimer, 1);
    window.onbeforeunload = () => {
      if (!statisticSent) {
        setStatisticSent(true);
        setStatistic({
          consultation_id: consultationId,
          spend_time: spendTime,
          statistic_type: 5,
        });
      }
      clearInterval(timer);
    };
    setStatistic({
      consultation_id: consultationId,
      statistic_type: 2,
    });

    return () => handleSendStatistic();
  }, []);

  return (
    <main className="page">
      <TopSection
        title={title}
        img={imageUrl}
        description={description}
        handleButtonClick={handleButtonClick}
      />
      <Services
        items={items}
        defaultActive={defaultActive}
        handleButtonClick={handleButtonClick}
      />
      {showRecommends && (
        <Recommends />
      )}
      <Sponsors />
      <FooterSection />
    </main>
  );
};
