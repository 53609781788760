import React, { Component } from 'react';
import _ from 'lodash';
import { AuthHeader, FormContainer, SignUpForm } from '../../';
import { EMAIL, PASSWORD, GP_TYPE } from '../../../constants';

class SignUp extends Component {
  constructor() {
    super();

    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      email_confirmation: '',
      password: '',
      password_confirmation: '',
      imc: '',
      county: '',
      user_type: '',
      practice_name: '',
      nmbi: '',
      gp_type: GP_TYPE[0].value.toString(),
      errors: {},
      showPassword: false,
      isChecked: false,
      agreeError: null,
      loading: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.authState.errorMessage === 'email has already been taken') {
      let errors = {};
      errors.email = 'This email address has already been registered';

      this.setState({ errors });
    }
  }

  handleFirstName = event => {
    const first_name = event.target.value;

    this.setState({ first_name });
  }

  handleLastName = event => {
    const last_name = event.target.value;

    this.setState({ last_name });
  }

  handleEmail = event => {
    const email = event.target.value;

    this.setState({ email });
  }

  handleEmailConfirmation = event => {
    const email_confirmation = event.target.value;

    this.setState({ email_confirmation });
  }

  handlePassword = event => {
    const password = event.target.value;

    this.setState({ password, password_confirmation: password });
  }

  onShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  onCkeckbox = () => {
    this.setState({
      isChecked: !this.state.isChecked
    });
  }

  handleIMC = event => {
    const imc = event.target.value;

    this.setState({
      imc,
      errors: {}
    });
  }

  handleNMBI = event => {
    const nmbi = event.target.value;

    this.setState({
      nmbi,
      errors: {}
    });
  }

  handlePracticeName = event => {
    const practice_name = event.target.value;

    this.setState({
      practice_name,
      errors: {}
    });
  }

  handleSelect = event => {
    const target = event.target;

    this.setState({
      [target.name]: target.value,
      errors: {}
    });
  }

  onSubmit = event => {
    event.preventDefault();

    const {
      first_name,
      last_name,
      email,
      email_confirmation,
      password,
      password_confirmation,
      isChecked,
      imc,
      county,
      user_type,
      gp_type,
      practice_name,
      nmbi
    } = this.state;
    const { authActions: { signUp } } = this.props;
    const isGP = user_type === '0';
    const isLocum = gp_type === '2';
    const isPracticeNurse = user_type === '2';
    let errors = {};

    this.setState({ loading: true });

    if(!isChecked) {
      this.setState({
        agreeError: 'You must agree to the Terms of Service and Privacy Policy.',
        loading: false
      });
    }
    if(!first_name || first_name === '') {
      errors.first_name = 'Please enter your first name.';
    }
    if(!last_name || last_name === '') {
      errors.last_name = 'Please enter your last name.';
    }
    if(!email || email === '') {
      errors.email = 'Please enter your email.';
    } else if(!EMAIL.test(email)) {
      errors.email = 'Invalid email address.';
    }
    if(!email_confirmation || email_confirmation === '') {
      errors.email_confirmation = 'Please repeat your email.';
    } else if(email && email_confirmation && email !== email_confirmation) {
      errors.email = ' ';
      errors.email_confirmation = 'Emails do not match.';
    }
    if(!password && password === '') {
      errors.password = 'Please enter your password.';
    } else if(!PASSWORD.test(password)) {
      errors.password = 'Password must be more than 7 characters & contain at least one number & one capital letter.';
    }
    if(!isPracticeNurse && (!imc || imc === '')) {
      errors.imc = 'IMC cant be blank.';
    }
    if(!county || county === '') {
      errors.county = 'County cant be blank.';
    }
    if(!user_type || user_type === '') {
      errors.user_type = 'User Type cant be blank.';
    }
    if(isGP && (!gp_type || gp_type === '')) {
      errors.gp_type = 'GP Type cant be blank.';
    }
    if((isGP || isPracticeNurse) && !isLocum && (!practice_name || practice_name === '')) {
      errors.practice_name = `Practice name can't be blank.`
    }
    if(isPracticeNurse && (!nmbi || nmbi === '')) {
      errors.nmbi = `NMBI can't be blank.`
    }

    if(_.isEmpty(errors) && isChecked) {
      const user = {
        first_name,
        last_name,
        email,
        email_confirmation,
        password,
        password_confirmation,
        imc: !isPracticeNurse ? imc : null,
        county,
        user_type,
        gp_type: isGP ? gp_type : null,
        practice_name: ((isGP || isPracticeNurse) && !isLocum) ? practice_name : null,
        nmbi: isPracticeNurse ? nmbi : null
      };

      signUp(user);
    } else {
      this.setState({ errors, loading: false });
    }
  }

  componentWillUnmount() {
    this.props.authActions.enterUsernameAndPassword();
  }

  render() {
    const { authState: { errorMessage } } = this.props;
    const { agreeError, user_type, gp_type } = this.state;
    const error = errorMessage === 'email has already been taken' ? '' : errorMessage;
    const isGP = user_type === '0';
    const isLocum = gp_type === '2';
    const isPracticeNurse = user_type === '2';

    return (
      <main>
        <AuthHeader
          title='Join GP Consult'
          description='Create your account absolutely free'
        />
        <FormContainer classStyle='confirm-user signup'>
          { error &&
            <span className='main-auth-error' style={ agreeError ? { marginLeft: '10%' } : {} }>{ error }</span>
          }
          <SignUpForm
            handleFirstName={ this.handleFirstName }
            handleLastName={ this.handleLastName }
            handleEmail={ this.handleEmail }
            handleEmailConfirmation={ this.handleEmailConfirmation }
            handlePassword={ this.handlePassword }
            onShowPassword={ this.onShowPassword }
            onSubmit={ this.onSubmit }
            onCkeckbox={ this.onCkeckbox }
            handleIMC={ this.handleIMC }
            handlePracticeName={ this.handlePracticeName }
            handleSelect={ this.handleSelect }
            handleNMBI={ this.handleNMBI }
            isGP={ isGP }
            isLocum={ isLocum }
            isPracticeNurse={ isPracticeNurse }
            { ...this.state }
          />
        </FormContainer>
      </main>
    )
  }
}

export default SignUp;