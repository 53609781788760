import { fork } from 'redux-saga/effects';
import * as authSagas from './authSagas';
import * as categoriesSagas from './categoriesSagas';
import * as consultationsSagas from './consultationsSagas';
import * as activitySagas from './activitySagas';
import * as additionalDataSagas from './additionalDataSagas';
import * as commentsSagas from './commentsSagas';
import * as currentUserSagas from './currentUserSagas';
import * as reportsSagas from './reportsSagas';
import * as suggestionSagas from './suggestionSagas';
import * as webinarsSagas from './webinarsSagas';
import * as contactUsSagas from './contactUsSagas';
import * as lorealStatisticSagas from './lorealStatisticSagas';
import * as nuggestSagas from './nuggestSagas';

export default function* rootSaga(store) {
  yield fork(authSagas.watchAuthSagas, store);
  yield fork(categoriesSagas.watchCategoriesSagas, store);
  yield fork(consultationsSagas.watchConsultationsSagas, store);
  yield fork(activitySagas.watchActivitySagas, store);
  yield fork(additionalDataSagas.watchAdditionalDataSagas, store);
  yield fork(commentsSagas.watchCommentsSagas, store);
  yield fork(currentUserSagas.watchCurrentUserSagas, store);
  yield fork(reportsSagas.watchReportsSagas, store);
  yield fork(suggestionSagas.watchSuggestionSagas, store);
  yield fork(webinarsSagas.watchGetWebinarsSagas, store);
  yield fork(contactUsSagas.watchContactUsSagas, store);
  yield fork(lorealStatisticSagas.watchLorealStatisticSagas, store);
  yield fork(nuggestSagas.watchNuggestSagas, store);
};
