import {CONTACT_US_ACTIONS} from '../constants';

export const sendMail = requestPayload => ({
  type: CONTACT_US_ACTIONS.SEND_MAIL,
  payload: {requestPayload},
});

export const sendMailSuccess = () => ({
  type: CONTACT_US_ACTIONS.SEND_MAIL_SUCCESS,
});

export const sendMailError = error => ({
  type: CONTACT_US_ACTIONS.SEND_MAIL_ERROR,
  payload: {error},
});
