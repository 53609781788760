import React from 'react';
import Linkify from 'react-linkify';
import CategoryCard from './CategoryCard';

const Categories = ({ categories, letters, user, notifications }) => (
  <section className="sec-categories-list">
    <div className="container">
      {notifications.map(notification => (
        <div key={notification.id} style={{textAlign: 'center'}}>
          <p style={{ margin: '10px 0' }}>
            <Linkify
              componentDecorator={ text =>
                <a href={ text } target='_blank' rel='noopener noreferrer'>{ text }</a>
              }
            >
              { notification.message }
            </Linkify>
          </p>
        </div>
      ))}
      {/* {user && (user.user_type === 1 || user.user_type === 'gp_trainee') ?
        <div style={{textAlign: 'center'}}>
          <p style={{ margin: '10px 0' }}>PrescriptionRevision.com - 27 clinical conditions covered. Fully reimbursable under the TSS and ICGP specialist funding. <a href='https://prescriptionrevision.com/' target="_blank">Click here for more</a></p>
        </div> : null
      } */}
      <div className="categories-title">
        <p>CONSULTATION CATEGORIES</p>
      </div>
      <div className="categories-container">
        { letters && letters.length > 0 && letters.map(letter =>
          <CategoryCard
            key={ letter }
            categories={ categories[letter] }
            letter={ letter }
          />
        )}
      </div>
    </div>
  </section>
);

export default Categories;