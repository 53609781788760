import React, {useState} from "react";
import {Service} from './Service';

export const Services = ({items, defaultActive, handleButtonClick}) => {
  const [selectedItem, setSelected] = useState(items[defaultActive]);
  const handleItemClick = () => {
    handleButtonClick(selectedItem.details.linkTitle);
  };

  return (
    <section className="page__services services">
      <div className="services__container _container">
        <div className="services__body">
          <div className="services__column">
            {items.map(item => (
              <Service
                key={item.id}
                item={item}
                setSelected={setSelected}
              />
            ))}
          </div>

          <div id="items-wrapper" className="services__desc">
            <span>{selectedItem.title}</span>
            <p>{selectedItem.details.description}
            </p>
            <a href={selectedItem.details.href} target="_blank" onClick={handleItemClick}>
              <button className="video-btn">{selectedItem.details.linkTitle}</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
