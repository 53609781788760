import { CONSULTATIONS_ACTIONS } from '../constants';

export const getConsultation = (id, mobile) => {
  return {
    type: CONSULTATIONS_ACTIONS.GET_CONSULTATION,
    payload: { id, mobile }
  };
}

export const getConsultationSuccess = (consultation, sponsor) => {
  return {
    type: CONSULTATIONS_ACTIONS.GET_CONSULTATION_SUCCESS,
    payload: { consultation, sponsor }
  };
}

export const getConsultationError = error => {
  return {
    type: CONSULTATIONS_ACTIONS.GET_CONSULTATION_ERROR,
    payload: { error }
  };
}

export const saveConsultation = consultation => {
  return {
    type: CONSULTATIONS_ACTIONS.SAVE_CONSULTATION,
    payload: { consultation }
  };
}

export const saveConsultationSuccess = successMessage => {
  return {
    type: CONSULTATIONS_ACTIONS.SAVE_CONSULTATION_SUCCESS,
    payload: { successMessage }
  };
}

export const saveConsultationError = error => {
  return {
    type: CONSULTATIONS_ACTIONS.SAVE_CONSULTATION_ERROR,
    payload: { error }
  };
}

export const editConsultation = consultation => {
  return {
    type: CONSULTATIONS_ACTIONS.EDIT_CONSULTATION,
    payload: { consultation }
  };
}

export const editConsultationSuccess = successMessage => {
  return {
    type: CONSULTATIONS_ACTIONS.EDIT_CONSULTATION_SUCCESS,
    payload: { successMessage }
  };
}

export const editConsultationError = error => {
  return {
    type: CONSULTATIONS_ACTIONS.EDIT_CONSULTATION_ERROR,
    payload: { error }
  };
}

export const clearConsultation = () => {
  return {
    type: CONSULTATIONS_ACTIONS.CLEAR_CONSULTATION
  };
}

export const deleteConsultation = consultation => {
  return {
    type: CONSULTATIONS_ACTIONS.DELETE_CONSULTATION,
    payload: { consultation }
  };
}

export const deleteConsultationSuccess = () => {
  return {
    type: CONSULTATIONS_ACTIONS.DELETE_CONSULTATION_SUCCESS
  };
}

export const deleteConsultationError = error => {
  return {
    type: CONSULTATIONS_ACTIONS.DELETE_CONSULTATION_ERROR,
    payload: { error }
  };
}

export const clearMessage = () => {
  return {
    type: CONSULTATIONS_ACTIONS.CLEAR_MESSAGE
  };
}

export const incrementSponsorClick = (consultation_id, sponsorID, mobile) => {
  return {
    type: CONSULTATIONS_ACTIONS.INCREMENT_SPONSOR_CLICK,
    payload: { consultation_id, mobile, sponsorID }
  };
}

export const incrementConsultationView = (consultation_id, sponsorID, mobile) => {
  return {
    type: CONSULTATIONS_ACTIONS.INCREMENT_CONSULTATION_VIEW,
    payload: { consultation_id, mobile, sponsorID }
  };
}

export const getUpdatedConsultations = meta => ({
  type: CONSULTATIONS_ACTIONS.GET_UPDATED_CONSULTATIONS,
  payload: {meta},
});

export const getUpdatedConsultationsSuccess = consultations => ({
  type: CONSULTATIONS_ACTIONS.GET_UPDATED_CONSULTATIONS_SUCCESS,
  payload: {consultations},
});

export const getUpdatedConsultationsNextPageSuccess = consultations => ({
  type: CONSULTATIONS_ACTIONS.GET_UPDATED_CONSULTATIONS_NEXT_PAGE_SUCCESS,
  payload: {consultations},
});

export const getUpdatedConsultationsError = error => ({
  type: CONSULTATIONS_ACTIONS.GET_UPDATED_CONSULTATIONS_ERROR,
  payload: {error},
});
