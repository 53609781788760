import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import classNames from 'classnames';
import LogoButton from './LogoButton';
import AuthRoutes from './AuthRoutes';
import {
  MenuBtn,
  MenuCross,
  HeaderSignInForm
} from '../../';
import { ROUTES, IMAGE_URL, EMAIL } from '../../../constants';
import AlertIcon from '../../../imgs/alert-icon.svg';

class Header extends React.Component {
  constructor() {
    super();

    this.state = {
      email: '',
      password: '',
      errors: '',
      backgroundColor: 'rgba(255, 255, 255, 0.8)'
    };
  }

  componentWillMount() {
    const { authActions: { getUserData } } = this.props;

    getUserData();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.authState.errorMessage) {
      setTimeout(this.props.authActions.enterUsernameAndPassword, 4000);
    }
  }

  listenScrollEvent = e => {
    if (window.scrollY > 100) {
      this.setState({ backgroundColor: 'white' });
    } else {
      this.setState({ backgroundColor: 'rgba(255, 255, 255, 0.8)' });
    }
  }

  toggleMenu = () => {
    const { globalState: { isOpen }, globalActions: { toggleMenu } } = this.props;

    toggleMenu(!isOpen);
  }

  openAddCategoryModal = () => {
    this.props.globalActions.toggleAddCategoryModal(true);
  }

  openEditCategoryModal = () => {
    const { globalActions, category } = this.props;

    globalActions.toggleEditCategoryModal(true, category);
  }

  componentWillUnmount() {
    const { globalState: { isOpen }, globalActions: { toggleMenu } } = this.props;

    toggleMenu(!isOpen);
  }

  handleEmail = event => {
    const email = event.target.value;

    this.setState({ email, errors: {} });
  }

  handlePassword = event => {
    const password = event.target.value;

    this.setState({ password, errors: {} });
  }

  onSubmit = event => {
    event.preventDefault();

    const { email, password } = this.state;
    const { authActions: { login } } = this.props;
    let errors = {};

    if(!email || email === '') {
      errors.email = 'Please enter your email.';
    } else if(!EMAIL.test(email)) {
      errors.email = 'Invalid email address.';
    }
    if(!password && password === '') {
      errors.password = 'Please enter your password.';
    }
    if(_.isEmpty(errors)) {
      login(email, password);
    } else {
      this.setState({ errors });
    }
  }

  closeMenu = () => {
    const { globalActions: { toggleMenu } } = this.props;

    toggleMenu(false);
  }

  render() {
    const {
      globalState: { isOpen },
      category,
      authState: { user, errorMessage },
      router: { location: { pathname } },
      authActions: { logout },
      currentUserState
    } = this.props;
    const { backgroundColor, email, password, errors } = this.state;
    const currentMenuIcon = isOpen
      ? MenuCross
      : MenuBtn;
    const isShowLoginForm = !user && pathname === ROUTES.HOME;

    return (
      <header className={classNames({'open-menu': isOpen})}>
        <div className="container" style={{ backgroundColor: backgroundColor, maxWidth: '100%' }}>
          <div className={ `header-static-wrapper ${ isShowLoginForm ? 'header-login-form' : '' }` }>
            <LogoButton toggleMenu={ this.closeMenu } />
            { isShowLoginForm &&
              <div className='header-form-wrapper'>
                { errorMessage &&
                  <span className='main-auth-error' style={{ marginLeft: 0 }}>{ errorMessage }</span>
                }
                <HeaderSignInForm
                  onSubmit={ this.onSubmit }
                  errors={ errors }
                  handleEmail={ this.handleEmail }
                  email={ email }
                  handlePassword={ this.handlePassword }
                  password={ password }
                  errorMessage={ errorMessage }
                />
                <Link to={ ROUTES.FORGOT_PASSWORD } className="text">Forgot password?</Link>
              </div>
            }
            { user && user.role !== 'admin' &&
              <div className="menu-button-block">
                <Link to={ ROUTES.HOME } onClick={ this.closeMenu } className={ `btn small outlined btn-add-category` }>HOME</Link>
                {/* { user && user.role !== 'pharma' &&
                  <Link to={ ROUTES.ACTIVITY } onClick={ this.closeMenu } className={ `btn small outlined btn-add-category` }>Activity</Link>
                } */}
                <Link to={ ROUTES.UPDATES } onClick={ this.closeMenu } className={ `btn small outlined btn-add-category` }>UPDATES</Link>
                <Link to={ ROUTES.NUGGETS } onClick={ this.closeMenu } className={ `btn small outlined btn-add-category` }>NUGGETS</Link>
                { user && user.role !== 'pharma' &&
                  <Link to={ ROUTES.PROFILE } onClick={ this.closeMenu } className={ `btn small outlined btn-add-category` } style={{ marginRight: 0 }}>Profile</Link>
                }
              </div>
            }
            <div className={ `menu-button-block right-button-block ${ (!user && pathname === ROUTES.HOME) ? 'hide-menu-for-desktop' : '' }` }>
              { user && user.role === 'admin' &&
                <Link to={ ROUTES.REPORTS } onClick={ this.closeMenu } className="btn small outlined btn-add-category btn-reports">
                  <img src={ AlertIcon } alt="icon" />
                </Link>
              }
              { user && user.role === 'admin' && !(category && pathname === ROUTES.CATEGORY_DETAIL.replace(':id', category.id)) &&
                <span
                  className="btn small outlined btn-add-category"
                  onClick={ category ? this.openEditCategoryModal : this.openAddCategoryModal }
                >
                  { category ? 'Edit category' : 'Add category' }
                </span>
              }
              { user && user.role === 'admin' && (category && pathname === ROUTES.CATEGORY_DETAIL.replace(':id', category.id)) &&
                <Link to={ ROUTES.CONSULT_CREATE } className="btn small outlined btn-add-category">Add Consultation</Link>
              }
              <button
                className='header-menu-btn'
                tabIndex="1"
                onClick={ this.toggleMenu }
              >
                { currentMenuIcon }
              </button>
            </div>
          </div>
          <div className="expanded-menu">
            <div className="menu-container">
              <div className="auth-block">
                { user &&
                  <div className="auth-ava">
                    { currentUserState.value && currentUserState.value.avatar_url ?
                      <div className="user-avatar">
                        <img src={ `${ IMAGE_URL }${ currentUserState.value.avatar_url }` } alt="User profile" />
                      </div> :
                      <div className="no-avatar avatar">
                        <span>{ user.first_name.charAt(0) }</span><span>{ user.last_name.charAt(0)  }</span>
                      </div>
                    }
                  </div>
                }
                <AuthRoutes
                  user={ user }
                  pathname={ pathname }
                  logout={ logout }
                  toggleMenu={ this.toggleMenu }
                />
              </div>
              <div className="nav-block">
                <div className="hlinks-wrapper">
                  { !user &&
                    <Link onClick={ this.toggleMenu } to={ ROUTES.HOME } className={ `hlink text ${ pathname === ROUTES.HOME ? 'active' : '' }` }>Home</Link>
                  }
                  <Link onClick={ this.toggleMenu } to={ ROUTES.WEBINARS } className={ `hlink text ${ pathname === ROUTES.WEBINARS ? 'active' : '' }` }>GPConsult.ie Webinars</Link>
                  <Link onClick={ this.toggleMenu } to={ ROUTES.CONTACT_US } className={ `hlink text ${ pathname === ROUTES.CONTACT_US ? 'active' : '' }` }>Contact Us</Link>
                  <Link onClick={ this.toggleMenu } to={ ROUTES.ABOUT_US } className={ `hlink text ${ pathname === ROUTES.ABOUT_US ? 'active' : '' }` }> About Us</Link>
                  <Link onClick={ this.toggleMenu } to={ ROUTES.TERMS_AND_CONDITIONS } className={ `hlink text ${ pathname === ROUTES.TERMS_AND_CONDITIONS ? 'active' : '' }` }>Terms & Conditions</Link>
                  <Link onClick={ this.toggleMenu } to={ ROUTES.PRIVACY_POLICY } className={ `hlink text ${ pathname === ROUTES.PRIVACY_POLICY ? 'active' : '' }` }>Privacy Policy</Link>
                  <Link onClick={ this.toggleMenu } to={ ROUTES.COOKIES_POLICY } className={ `hlink text ${ pathname === ROUTES.COOKIES_POLICY ? 'active' : '' }` }> Cookies Policy</Link>
                </div>
              </div>
            </div>
            <div className="footer-container footer-menu">
              <div className="footer--cred">
                <div className="gpc-cred">
                  <span>{`© ${moment().format('YYYY')} Prescription Revision Ltd`}</span>
                  <span>Ballyhickey, Cahir, Co. Tipperary</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

const mapStateToProps = state => {
  return {
    router: state.router
  };
}

export default connect(mapStateToProps)(Header);