import React from "react";

export const Sponsors = () => {
  return (
    <div className="sponsors__main-block main-block">
      <div className="sponsors-block__container _container">
        <div className="sponsors-block__body">
          <img src={require("../img/loreal_logo.webp")} alt="" />
          <div className="sponsors-block__text">
            <span>“L'Oréal Active Cosmetics is committed to improving skin health for everyone through our support to healthcare professionals in improving patient quality of life.”</span>
          </div>
          <div className="sponsors-block__desc">
            <p>
                The Active Cosmetics Division is the world leader in dermocosmetics, with our international skincare brands recommended by healthcare professionals and distributed in healthcare outlets worldwide, including, pharmacies, drugstores, medi-spa and e-retailers.
            </p>
            <p>
                Our 4 skincare expertise brands provide high quality ingredients through innovative delivery systems and advanced technology. It is our mission to help everyone in their quest for healthy and beautiful skin.
            </p>
            <p>
                Our clinically proven products help to manage skin concerns including acne, eczema, sun protection and the appearance of signs of ageing.
            </p>
          </div>
          <div className="sponsor-companies">
            <img src={require("../img/png/1@3x.png")} alt="" />
            <img src={require("../img/png/2@3x.png")} alt="" />
            <img src={require("../img/png/3@3x.png")} alt="" />
            <img src={require("../img/png/4@3x.png")} alt="" />
          </div>
        </div>
      </div>
      <div className="sponsors-block__image _ibg">
        <img src={require("../img/sponsors_bg_new.png")} alt="cover" />
      </div>
    </div>
  );
};
