import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import { AuthHeader } from '../../';

const PrivacyPolicy = () => (
  <main>
    <AuthHeader
      title='Privacy Policy'
      description=''
    />
    <div className='container text-container'>
      <p><strong>Introduction</strong></p>
      <p>Prescription Revision Limited (“GPConsult”) fully respects and protects your right to privacy in relation to your interactions with this app. We have adopted the following statement to safeguard your personal information and to protect its confidentiality.</p>
      <p>You should read this Privacy Statement carefully before using this app. If you do not read it or if you disagree with any aspect of this Privacy Statement, you should not use this app. Your use of this app signifies your agreement to be bound by the terms of this Privacy Statement.</p>
      <p>Please note that this Privacy Statement is subject to Prescription Revision Ltd’s <Link to={ ROUTES.TERMS_AND_CONDITIONS }><strong>Terms of Service</strong></Link>. We recommend that you read the <Link to={ ROUTES.TERMS_AND_CONDITIONS }>Terms of Service</Link>. If there is a conflict between the <Link to={ ROUTES.TERMS_AND_CONDITIONS }>Terms of Service</Link> and this Privacy Statement, the <Link to={ ROUTES.TERMS_AND_CONDITIONS }>Terms of Service</Link> shall prevail. Except as otherwise provided, terms used herein and defined in the <Link to={ ROUTES.TERMS_AND_CONDITIONS }>Terms of Service</Link> shall have the same meaning that is given to them in the Terms of Service.</p>
      <p><strong>The Collection of Information</strong></p>
      <p>Our primary goal in collecting personal information is to provide you with the best and most useful content on our website, and to help improve your online experience.</p>
      <p>If you are seeking to use the services offered by GPConsult, then you will be asked to register. We use any personal information you provide to identify you as a qualified medical practitioner, to process your registration and to deliver relevant information about you to registered medical practitioners and other healthcare provided regulated by law. We will not give or sell your personal information to any third parties for marketing purposes without your consent.</p>
      <p>Registration will oblige you to provide certain personal information to us. Such information will include, without limitation, personal details such as your name, practice address, email address and your Irish Medical Council registration number or Nursing and Midwifery Board PIN.</p>
      <p>We may use your email address to contact you from time-to-time to update you on matters concerning the service we provide. We may also use your email address to send you information and / or promotional content from partners and / or carefully selected third parties. You consent to us contacting you in this regard. You can unsubscribe from this informational service at any stage and still remain a member of GPConsult.</p>
      <p>You also acknowledge and agree that in certain circumstances we may be obliged to disclose personal information that you have provided to us to third parties, for example, in order to conform to any requirements of law or to comply with any legal process, as well as to protect and defend our rights.</p>
      <p>Certain information you have provided to us will be made available to other registered users of GPConsult, via a profile which discloses certain information about you.</p>
      <p><strong>The Use of Information by Us</strong></p>
      <p>Certain kinds of information is collected about users of GPConsult in order to:</p>
      <p>Help us to monitor and improve the services we offer.</p>
      <p>Sell advertising space on <a href='www.gpconsult.ie' target='_blank'>www.gpconsult.ie</a>.</p>
      <p>To protect <a href='www.gpconsult.ie' target='_blank'>www.gpconsult.ie and our users</a>.</p>
      <p>In common with other websites and apps that you may visit, you should be aware that each time you visit the GPConsult website, two general levels of information about your visit can be retained. The first level comprises information which is personal or particular to a specific visitor who knowingly chooses to provide that information. Such information is collected through the use of Cookies.</p>
      <p>The second level of information about which data relating you your visit can be retained comprises statistical and/or analytical information collected on an aggregate and non-individual-specific basis. This type of non- personal information and data can be collected through the standard operation of internet servers and logs as well as Cookies and other technical mechanisms. The data obtained provides us with statistical information about all the users who visit the GPConsult website and the pages that they visit. This information helps us monitor traffic on the GPConsult website so that we can manage the app’s capacity as efficiently as possible.</p>
      <p><strong>External Links</strong></p>
      <p>GPConsult may contain links to other websites and resources. If you access those links, you will leave our website. Prescription Revision Limited is not responsible for the content of external internet websites. We encourage you to review the privacy statement of any company before submitting your personal information. We cannot accept responsibility for external websites or resources, or for any loss or damage that may arise from your use of them.</p>
      <p><strong>Sale of Business</strong></p>
      <p>We reserve the right to transfer personal information (including any personal information you have provided to us) to a third party in the event of a sale, merger, liquidation, receivership or transfer of all or substantially all of the assets of Prescription Revision Limited, a subsidiary, or line of business associated with our business. You will be notified in the event of any such transfer and you will be afforded an opportunity to opt-out if that is your wish.</p>
      <p><strong>Amending our Privacy Statement</strong></p>
      <p>Prescription Revision Limited reserves the right, at its sole discretion, to amend this Privacy Statement at any time. If there are updates to the content of this Privacy Statement, we will post those changes and shall update the revision date at the top of this document, so you will always know what information we collect online, how we use it, and what choices you have. Whilst we will endeavour to bring any material changes to this Privacy Statement to your attention, we encourage you to check our website from time to time at <a href='www.gpconsult.ie'>www.gpconsult.ie</a> for any amendments which may be made.</p>
      <p><strong>Data Protection</strong></p>
      <p>For the purpose of the Data Protection Act 1998 (the Act), the data controller is Prescription Revision Limited of Suite 104, 4/5 Burton Hall Road, Sandyford, Dublin 18, D18 A094.</p>
      <p><strong>Contacting Us</strong></p>
      <p>If you have any questions about this Privacy Policy or you wish to make a complaint, please feel free to contact us through our <a href='mailto:support@gpconsult.ie'>support@gpconsult.ie</a> email address or write to us at: Prescription Revision Limited, Suite 104, 4/5 Burton Hall Road, Sandyford, Dublin 18, D18 A094.</p>
      <p>Visitors should be aware that each time they visit a website or app two general levels of information about their visit can be retained. The first level comprises statistical and other analytical information collected on an aggregate and non-individual specific basis of all users who visit the site, and the second is information that is personal or particular to a specific visitor who knowingly chooses to provide that information. It is policy of this app to respect and protect the privacy of our users and never wilfully disclose individually identifiable information about our users to any third party without first receiving that user’s permission.</p>
      <p>We may, however, occasionally email you with information or queries about your registration or accounts, for instance to request permissions or advise you of changes.</p>
      <p>Via the website you may have an opportunity to send us information relating to you such as through the "Contact Us" pages. By choosing to participate in this, you will be providing us with some level of personal information relating to you. This information will only be used by us for:</p>
      <ul>
        <li>the purposes for which it was provided by you;</li>
        <li>verification purposes and statistical analysis;</li>
        <li>to provide you with details of products, services, contests, competitions or promotions being provided or run by Prescription Revision Limited or any of its associated companies or any third party that we may select and which we may think would be of interest to you, in line with your request to receive this information.</li>
      </ul>
      <p><strong>Stored Personal Information</strong></p>
      <p>As a registered user of GPConsult, whenever you use our services we aim to provide you with access to your personal information. If that information is wrong, we strive to give you ways to update it quickly or to delete it – unless we have to keep that information for legitimate business or legal purposes. When updating your personal information, we may ask you to verify your identity before we can act on your request.</p>
      <p>If at any time after giving us this information you decide that you no longer wish us to hold or use this information, or you wish to change this information, you are free to notify us, and we will remove or rectify the information promptly. We do not collect or keep your personal data unless it is necessary for the purposes(s) set out above nor do we keep your personal data for longer than is necessary. You have the right to request a copy of the information which we hold about you and to request its correction or deletion. To obtain a copy of this information, please send an email to <a href='mailto:support@gpconsult.ie'>support@gpconsult.ie</a>.</p>
      <p><strong>Security</strong></p>
      <p>GPConsult accounts are secured by user-created passwords. Due to the nature of the internet, we cannot guarantee the protection of your personal data and therefore we are not responsible for any loss or damage resulting to you when our app is used. However, we will take all reasonable steps (including appropriate industry and technical protocols and standards) to protect your personal data, and any financial information you may from time to time opt to provide to us for the purposes of transacting a service on the GPConsult website.</p>
      <p><strong>Governing Law and Jurisdiction</strong></p>
      <p>This Privacy Statement is governed by the laws of Ireland. It is a condition precedent to the use of GPConsult that any such user submits to the sole and exclusive jurisdiction of the Courts of Ireland and to the application of the law in that jurisdiction, including any party accessing such information or facilities acting on their own behalf or on behalf of others.</p>
      <p><strong>Disclaimer</strong></p>
      <p>PRESCRIPTION REVISION LIMITED ITS DIRECTORS, EMPLOYEES, SERVANTS AND AGENTS, AFFILIATES OR OTHER REPRESENTATIVES AND THEIR RESPECTIVE PARENT AND SUBSIDIARY COMPANIES, SHALL NOT BE LIABLE IN RESPECT OF ANY CLAIMS, EMERGENCIES, DEMANDS, CAUSES OF ACTION, DAMAGES, LOSSES, EXPENSES, INCLUDING WITHOUT LIMITATION, REASONABLE LEGAL FEES AND COSTS OF PROCEEDINGS ARISING OUT OF OR IN CONNECTION WITH THE USE AND/OR DISSEMINATION OF PERSONAL INFORMATION RELATING TO YOU IN ACCORDANCE WITH THIS PRIVACY STATEMENT.</p>
    </div>
  </main>
);

export default PrivacyPolicy;