import React from 'react';
import {IMAGE_URL} from '../../../constants';
import BackArrow from '../../../imgs/back-arrow.svg';
import {ImageMapper} from './ImageMap';

const LOREAL_IDS = {
  36: 'Acne',
  234: 'Acne',
  40: 'Actinic Keratosis',
  33: 'Atopic Eczema',
  34: 'Contact Dermatitis',
  162: 'Psoriasis',
  39: 'Seborrhoeic Dermatitis',
};
const MAPPED = [116, 119, 130];
const COORDS = {
  116: [{
    desk: '170,70,190,80',
    mob: '70,30,90,34',
    url: 'https://www.medicines.ie/medicines/fluenz-tetra-nasal-spray-suspension-34994/spc',
  }, {
    desk: '5,75,105,85',
    mob: '10,32,35,36',
    url: 'https://rcpi.access.preservica.com/uncategorized/IO_fc7e49fa-b64e-4f5a-9f81-4404d862f43e/',
  }],
  119: [{
    desk: '170,70,190,80',
    mob: '70,30,90,34',
    url: 'https://www.medicines.ie/medicines/fluenz-tetra-nasal-spray-suspension-34994/spc',
  }, {
    desk: '5,75,105,85',
    mob: '10,32,35,36',
    url: 'https://rcpi.access.preservica.com/uncategorized/IO_fc7e49fa-b64e-4f5a-9f81-4404d862f43e/',
  }],
  130: [{
    desk: '140,120,190,130',
    mob: '50,35,80,42',
    url: 'https://www.medicines.ie/medicines/brintellix-10-mg-film-coated-tablets-34817/spc',
  }, {
    desk: '120,175,290,195',
    mob: '40,55,110,65',
    url: 'https://ireland-products.progress.im/en/',
  }],
};

const ConsultationHeader = ({
  consultation,
  goBack,
  user,
  onSponsorClick,
  sponsor,
  setStatistic,
  handleCopy,
}) => {
  const linkView = () => {
    return LOREAL_IDS[consultation.id] ? (
      // <p>{`To learn more about ${LOREAL_IDS[consultation.id]} click on the image:`}</p>
      <p>To access more scientific content and accredited courses click on the image</p>
    ) : null;
  };
  const handleStatistic = () => {
    setStatistic({
      consultation_id: consultation.id,
      statistic_type: 0,
      button_name: LOREAL_IDS[consultation.id] || consultation.title,
    });
  };
  const renderDefault = () => {
    return (
      <a
        href={ `${ sponsor.url ? sponsor.url : '' }` }
        target='_blank'
        rel="noopener noreferrer"
        onClick={ onSponsorClick }
        // onClick={ handleStatistic }
      >
        <div className="navigation-sponsor">
          <div className='support-logo'>
            <img src={ `${ IMAGE_URL }${ sponsor.sponsor_logo_url }` } alt="support logo" />
          </div>
          {/* <div className='sponsor-title'>
            <p>{ 'Supported by: ' }</p>
          </div> */}
        </div>
      </a>
    );
  };
  const renderMapped = () => {
    const url = consultation.id === 129 ? 'https://gpconsult.ie/images/sponsors/130/New_Brintellix_Ad_GPC.ie_Lundbeck.jpg?1706875455' : `${ IMAGE_URL }${ sponsor.sponsor_logo_url }`;
    const coords = consultation.id === 129 ? COORDS[130] : COORDS[sponsor.id];

    return (
      <div style={{display: 'flex', flex: '1 1', justifyContent: 'center'}}>
        <div className="navigation-sponsor">
          <ImageMapper url={url} onClick={onSponsorClick} coords={coords} />
        </div>
      </div>
    );
  };
  const renderSponsor = () => {
    return MAPPED.includes(sponsor.id) || consultation.id === 129 ? renderMapped() : renderDefault();
  };

  const isRandomSponsor = sponsor && !consultation.is_sponsored && user.user_type !== 'practice_nurse';

  return (
    <div className="navigation-back sponsor">
      <button className='btn' onClick={ goBack }>
        <div className="back-container">
          <div className="back-arrow">
            <img src={ BackArrow } alt="Back arrow"/>
          </div>
          <div className="back-text">
            <p className="blue">{ consultation.category.title }</p>
          </div>
        </div>
      </button>
      { consultation.is_sponsored && user.user_type !== 'practice_nurse' &&
        <a
          href={ `${ consultation.sponsor_url ? consultation.sponsor_url : '' }` }
          target='_blank'
          rel="noopener noreferrer"
          // onClick={ onSponsorClick }
          onClick={ handleStatistic }
        >
          <div className="navigation-sponsor">
            {/* <div className='sponsor-title'>
              <p>{ 'Kindly supported by: ' }</p>
            </div> */}
            {linkView()}
            <div className='support-logo'>
              <img src={ `${ IMAGE_URL }${ consultation.sponsor_logo_url }` } alt="support logo"/>
            </div>
          </div>
        </a>
      }
      {isRandomSponsor && renderSponsor()
      // sponsor && !consultation.is_sponsored && user.user_type !== 'practice_nurse' &&
      //   <a
      //     href={ `${ sponsor.url ? sponsor.url : '' }` }
      //     target='_blank'
      //     rel="noopener noreferrer"
      //     onClick={ onSponsorClick }
      //     // onClick={ handleStatistic }
      //   >
      //     <div className="navigation-sponsor">
      //       <div className='support-logo'>
      //         <img src={ `${ IMAGE_URL }${ sponsor.sponsor_logo_url }` } alt="support logo" />
      //       </div>
      //       {/* <div className='sponsor-title'>
      //         <p>{ 'Supported by: ' }</p>
      //       </div> */}
      //     </div>
      //   </a>
      }
      <button
        className='btn small outlined btn-add-category'
        onClick={handleCopy}
        type='button'
      >
        Copy to clipboard
      </button>
    </div>
  );
};

export default ConsultationHeader;