import { takeEvery, put, call  } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { CONTACT_US_ACTIONS, ROUTES } from '../constants';
import { contactUsActions } from '../actions';
import { processRequest } from '../services/Api';

function* handleSendMail(action) {
  const {payload} = action || {};
  const {requestPayload} = payload || {};
  const contact_request = {contact_request: requestPayload};
  try {
    const { data } = yield call(processRequest, '/contact_requests', 'POST', contact_request);
    if(data.request) {
      yield put(contactUsActions.sendMailSuccess());
      yield put(replace(ROUTES.HOME));
    }
  } catch (e) {
    console.log(e);
    yield put(contactUsActions.sendMailError(e));
  }
}

export function* watchContactUsSagas() {
  yield takeEvery(CONTACT_US_ACTIONS.SEND_MAIL, handleSendMail);
};