import React from "react";

export const Recommends = () => {
  return (
    <div className="recommends__main-block main-block">
      <div className="recommends-block__container _container">
        <div className="recommends-block__body">
            <h1 className="recommends-block__title">What is
              <strong>Effaclar Spotscan</strong></h1>
            <div className="recommends-block__text">
              <span>Effaclar Spotscan is a skin analyser developed with dermatologists for blemish-prone skin. Powered by artificial inteligence, Spotscan analyses your skin and recommends a personalised skincare routine based on your scan.</span>
            </div>
            <div className="recommends-block__desc">
                Patients will be able to monitor the progress of their acne treatment, therefore reducing risk of non-compliance.
            </div>
            <div className="qr__container">
                <div className="qr-img">
                  <img src={require("../img/qr_bg.webp")} alt="" />
                </div>
                <div className="qr-line"></div>
                <div className="qr-desc">
                  <p>See your result in 3 simple steps. Scan the QR code with your mobile to try it now.</p>
                </div>
            </div>
        </div>
      </div>
      <div className="recommends-block__image _ibg">
        <img src={require("../img/face_bg@3x.webp")} alt="cover" />
      </div>
    </div>
  );
};
