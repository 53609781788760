import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { currentUserActions, authActions } from '../../actions';
import { Profile } from '../../components';

function mapStateToProps(state) {
  return {
    currentUser: state.currentUserState.value,
    updateErrors: state.currentUserState.updateErrors,
    message: state.currentUserState.message,
    changePasswordErrors: state.authState.errorMessage,
    successPasswordMessage: state.authState.successMessage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    currentUserActions: bindActionCreators(currentUserActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
