import React from "react";

export const ImageMapper = ({url, onClick, coords}) => {
	const click = () => {
		if (onClick) {
			onClick();
		}
	}
  const isMob = window.innerWidth < 620;

  return (
    <div className='support-logo' style={{position: 'relative'}}>
      <img
        src={url}
        useMap={`#support-logo`}
      />
      <map name='support-logo' id="support-logo">
        {/* <svg
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          // xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          data-id="4de2d3f3-ddfd-67b3-6126-d6c187c06db0"
          style={{position: "absolute", zIndex: 1000, overflow: 'hidden', top: '0px', left: '0px'}}
        >
          <g>
            <rect
              x="170"
              y="70"
              width="20"
              height="10"
              style={{fill: "red", fillOpacity: '0.2', stroke: 'rgb(255, 255, 255)', strokeWidth: '0px', cursor: 'pointer'}}
              data-fill="rgba(0, 0, 0, 0)"
              data-index="0"
            />
          </g>
          <g>
            <rect
              x="5"
              y="75"
              width="100"
              height="10"
              style={{fill: "green", fillOpacity: '0.2', stroke: 'rgb(255, 255, 255)', strokeWidth: '0px', cursor: 'pointer'}}
              data-fill="rgba(0, 0, 0, 0)"
              data-index="1"
            />
          </g>
          <g>
            <rect
              x="70"
              y="30"
              width="30"
              height="4"
              style={{fill: "red", fillOpacity: '0.2', stroke: 'rgb(255, 255, 255)', strokeWidth: '0px', cursor: 'pointer'}}
              data-fill="rgba(0, 0, 0, 0)"
              data-index="0"
            />
          </g>
          <g>
            <rect
              x="10"
              y="32"
              width="25"
              height="4"
              style={{fill: "green", fillOpacity: '0.2', stroke: 'rgb(255, 255, 255)', strokeWidth: '0px', cursor: 'pointer'}}
              data-fill="rgba(0, 0, 0, 0)"
              data-index="1"
            />
          </g>
          <g>
            <rect
              x="140"
              y="120"
              width="50"
              height="10"
              style={{fill: "red", fillOpacity: '0.5', stroke: 'rgb(255, 255, 255)', strokeWidth: '0px', cursor: 'pointer'}}
              data-fill="rgba(0, 0, 0, 0)"
              data-index="0"
            />
          </g>
          <g>
            <rect
              x="120"
              y="175"
              width="170"
              height="20"
              style={{fill: "green", fillOpacity: '0.5', stroke: 'rgb(255, 255, 255)', strokeWidth: '0px', cursor: 'pointer'}}
              data-fill="rgba(0, 0, 0, 0)"
              data-index="1"
            />
          </g>
          <g>
            <rect
              x="60"
              y="38"
              width="20"
              height="4"
              style={{fill: "red", fillOpacity: '0.5', stroke: 'rgb(255, 255, 255)', strokeWidth: '0px', cursor: 'pointer'}}
              data-fill="rgba(0, 0, 0, 0)"
              data-index="0"
            />
          </g>
          <g>
            <rect
              x="55"
              y="55"
              width="55"
              height="10"
              style={{fill: "green", fillOpacity: '0.5', stroke: 'rgb(255, 255, 255)', strokeWidth: '0px', cursor: 'pointer'}}
              data-fill="rgba(0, 0, 0, 0)"
              data-index="1"
            />
          </g>
        </svg> */}
        {coords.map((coord, index) => (
          <area key={`key_coords_${index + 1}`} onClick={click} shape="rect" coords={isMob ? coord.mob : coord.desk} href={coord.url} data-index={index} target="_blank"></area>
        ))}
      </map>
    </div>
  );
};
