import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { globalActions, lorealStatisticActions } from '../../actions';
import { Loreal } from '../../components';
import { LOREAL_TYPES, LOREAL_ITEMS } from '../../constants';

const mapStateToProps = state => {
  return {
    globalState: state.globalState,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    globalActions: bindActionCreators(globalActions, dispatch),
    setStatistic: bindActionCreators(lorealStatisticActions.setStatistic, dispatch),
  };
}

const LorealScreens = ({match, globalActions, setStatistic}) => {
  const {toggleFooter} = globalActions;
  const {title} = match.params || {};
  const product = title ? LOREAL_ITEMS[title] : LOREAL_ITEMS[LOREAL_TYPES.ACNE_IN_FEMALE];

  useEffect(() => {
    toggleFooter(true);
  }, []);

  return (
    <Loreal
      title={product.title}
      description={product.description}
      imageUrl={product.imageUrl}
      items={product.items}
      showRecommends={product.showRecommends}
      defaultActive={product.activeItem}
      consultationId={product.consultationId}
      setStatistic={setStatistic}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LorealScreens);