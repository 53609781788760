import {LOREAL_STATISTIC_ACTIONS} from '../constants';

export const setStatistic = requestData => ({
  type: LOREAL_STATISTIC_ACTIONS.SET_STATISTIC,
  payload: {requestData},
});

export const setStatisticSuccess = () => ({
  type: LOREAL_STATISTIC_ACTIONS.SET_STATISTIC_SUCCESS,
});

export const setStatisticError = error => ({
  type: LOREAL_STATISTIC_ACTIONS.SET_STATISTIC_ERROR,
  payload: {error},
});
