import keymirror from 'keymirror';

export default keymirror({
  GET_CATEGORIES: null,
  GET_CATEGORIES_SUCCESS: null,
  GET_CATEGORIES_ERROR: null,
  GET_CATEGORY: null,
  GET_CATEGORY_SUCCESS: null,
  GET_CATEGORY_ERROR: null,
  CHANGE_CATEGORY: null,
  CREATE_CATEGORY: null,
  CREATE_CATEGORY_SUCCESS: null,
  CREATE_CATEGORY_ERROR: null,
  DELETE_CATEGORY: null,
  DELETE_CATEGORY_SUCCESS: null,
  DELETE_CATEGORY_ERROR: null,
  EDIT_CATEGORY: null,
  EDIT_CATEGORY_SUCCESS: null,
  EDIT_CATEGORY_ERROR: null,
  CLEAR_CATEGORY: null,
  GET_NOTIFICATIONS_SUCCESS: null,
});
