import { takeEvery, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { CONSULTATIONS_ACTIONS, ROUTES } from '../constants';
import { consultationsActions, authActions, categoriesActions } from '../actions';
import { processRequest } from '../services/Api';

function* handleGetConsultation(action) {
  try {
    const { id, mobile } = action.payload;
    const { data } = yield call(processRequest, `/consultations/${ id }`, 'GET');

    if(data.consultation) {
      yield put(consultationsActions.getConsultationSuccess(data.consultation, data.sponsor));

      // if(typeof mobile === 'boolean') {
      //   const sponsorID = data.consultation.is_sponsored ? null : data.sponsor.id;
      //   yield put(consultationsActions.incrementConsultationView(id, mobile, sponsorID));
      // }

    } else if(data.error_message) {
      yield put(consultationsActions.getConsultationError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(consultationsActions.getConsultationError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess(statusText));
      yield put(replace(ROUTES.HOME));
    } else {
      yield put(consultationsActions.getConsultationError('Internal server error.'));
    }
  }
}

function* handleSaveConsultation(action) {
  try {
    const { consultation } = action.payload;
    const {
      category_id,
      subcategory,
      disclaimer,
      title,
      history,
      exam,
      impresion,
      plan,
      aditional_info,
      is_sponsored,
      sponsor_title,
      sponsor_logo,
      sponsor_url,
      attachments
    } = consultation;
    const formData = new FormData();

    formData.append('consultation[category_id]', category_id);
    formData.append('consultation[subcategory]', subcategory);

    if(disclaimer)
      formData.append('consultation[disclaimer]', disclaimer);
    if(title)
      formData.append('consultation[title]', title);
    if(history)
      formData.append('consultation[history]', history);
    if(exam)
      formData.append('consultation[exam]', exam);
    if(impresion)
      formData.append('consultation[impresion]', impresion);
    if(plan)
      formData.append('consultation[plan]', plan);
    if(aditional_info)
      formData.append('consultation[aditional_info]', aditional_info);
    if(is_sponsored) {
      formData.append('consultation[is_sponsored]', is_sponsored);
      formData.append('consultation[sponsor_title]', sponsor_title);
      formData.append('consultation[sponsor_logo]', sponsor_logo);
      formData.append('consultation[sponsor_url]', sponsor_url);
    }
    if(attachments)
      attachments.forEach((attachment, index) => {
        formData.append(`consultation[attachments_attributes[${ index }]document]`, attachment.attachment);
        formData.append(`consultation[attachments_attributes[${ index }]section]`, attachment.section);
      });

    const { data } = yield call(processRequest, `/consultations`, 'POST', formData, false);

    if(data.consultation) {
      yield put(consultationsActions.saveConsultationSuccess());
      yield put(categoriesActions.getCategory(consultation.category_id, ''));
      yield put(replace(`${ ROUTES.CATEGORY_DETAIL.replace('/:id', '') }/${ consultation.category_id }`));
    } else if(data.error_message) {
      yield put(consultationsActions.getConsultationError(data.error_message));
    }
  } catch(e) {
    console.log(e);
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(consultationsActions.saveConsultationError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess(statusText));
      yield put(replace(ROUTES.HOME));
    } else {
      yield put(consultationsActions.saveConsultationError('Internal server error.'));
    }
  }
}

function* handleEditConsultation(action) {
  try {
    const { consultation } = action.payload;
    const {
      consultation_id,
      category_id,
      subcategory,
      disclaimer,
      title,
      history,
      exam,
      impresion,
      plan,
      aditional_info,
      is_sponsored,
      sponsor_title,
      sponsor_logo,
      sponsor_url,
      attachments,
      removedAttachmentsIds
    } = consultation;
    const formData = new FormData();

    formData.append('consultation[category_id]', category_id);
    formData.append('consultation[subcategory]', subcategory);
    formData.append('consultation[history]', history);
    formData.append('consultation[exam]', exam);
    formData.append('consultation[impresion]', impresion);
    formData.append('consultation[plan]', plan);
    formData.append('consultation[aditional_info]', aditional_info);

    if(disclaimer)
      formData.append('consultation[disclaimer]', disclaimer);
    if(title)
      formData.append('consultation[title]', title);
    if(is_sponsored && sponsor_logo) {
      formData.append('consultation[is_sponsored]', is_sponsored);
      formData.append('consultation[sponsor_logo]', sponsor_logo);
    }
    if(is_sponsored && sponsor_title) {
      formData.append('consultation[sponsor_title]', sponsor_title);
    }
    if(is_sponsored && sponsor_url) {
      formData.append('consultation[sponsor_url]', sponsor_url);
    }
    if(!is_sponsored) {
      formData.append('consultation[is_sponsored]', is_sponsored);
    }
    attachments.forEach((attachment, index) => {
      if(!attachment.id) {
        formData.append(`consultation[attachments_attributes[${ index }]document]`, attachment.attachment);
        formData.append(`consultation[attachments_attributes[${ index }]section]`, attachment.section);
      }
    });
    removedAttachmentsIds.forEach(attachment => {
      if(attachment) {
        formData.append(`consultation[attachments_attributes[id]]`, attachment);
        formData.append(`consultation[attachments_attributes[_destroy]]`, true);
      }
    });

    const { data } = yield call(processRequest, `/consultations/${ consultation_id }`, 'PUT', formData, false);

    if(data.consultation) {
      yield put(consultationsActions.editConsultationSuccess('Your changes have been confirmed.'));
      yield put(categoriesActions.getCategory(consultation.category_id, ''));
      yield put(replace(`${ ROUTES.CATEGORY_DETAIL.replace('/:id', '') }/${ consultation.category_id }`));
    } else if(data.error_message) {
      yield put(consultationsActions.editConsultationError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(consultationsActions.editConsultationError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess(statusText));
      yield put(replace(ROUTES.HOME));
    } else {
      yield put(consultationsActions.editConsultationError('Internal server error.'));
    }
  }
}

function* handleDeleteConsultation(action) {
  try {
    const { consultation } = action.payload;

    const { data } = yield call(processRequest, `/consultations/${ consultation.id }`, 'DELETE');

    if(data.message) {
      yield put(consultationsActions.deleteConsultationSuccess());
      yield put(categoriesActions.getCategories());
      yield put(categoriesActions.getCategory(consultation.category_id));
    } else if(data.error_message) {
      yield put(consultationsActions.deleteConsultationError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(consultationsActions.deleteConsultationError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess(statusText));
      yield put(replace(ROUTES.HOME));
    } else {
      yield put(consultationsActions.deleteConsultationError('Internal server error.'));
    }
  }
}

function* handleIncrementSponsorClick(action) {
  try {
    const { consultation_id, mobile, sponsorID } = action.payload;
    // const requestPayload = sponsorID ? { mobile, sponsor_id: sponsorID } : { mobile };
    const requestPayload = sponsorID ? { sponsor_id: sponsorID, statistic_type: 0 } : { statistic_type: 0 };

    // yield call(processRequest, `/consultations/${ consultation_id }/sponsor_click`, 'POST', requestPayload);
    yield call(processRequest, `/consultations/${ consultation_id }/set_sponsor_statistic`, 'POST', requestPayload);
  } catch(e) {
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      console.warn('INCREMENT_SPONSOR_CLICK_ERROR', message);
    } else if(status === 401) {
      yield put(authActions.logoutSuccess(statusText));
      yield put(replace(ROUTES.HOME));
    } else {
      console.warn('INCREMENT_SPONSOR_CLICK_ERROR', 'Internal server error.');
    }
  }
}

function* handleIncrementConsultationView(action) {
  try {
    const { consultation_id, mobile, sponsorID } = action.payload;
    // const requestPayload = sponsorID ? { mobile, sponsor_id: sponsorID } : { mobile };
    const requestPayload = sponsorID ? { sponsor_id: sponsorID, statistic_type: 2 } : { statistic_type: 2 };

    // yield call(processRequest, `/consultations/${ consultation_id }/views_count`, 'POST', requestPayload);
    yield call(processRequest, `/consultations/${ consultation_id }/set_sponsor_statistic`, 'POST', requestPayload);
  } catch(e) {
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      console.warn('INCREMENT_CONSULTATION_VIEW_ERROR', message);
    } else if(status === 401) {
      yield put(authActions.logoutSuccess(statusText));
      yield put(replace(ROUTES.HOME));
    } else {
      console.warn('INCREMENT_CONSULTATION_VIEW_ERROR', 'Internal server error.');
    }
  }
}

function* handleGetUpdatedConsultations(action) {
  try {
    const { meta } = action.payload || {};
    const { page, per_page } = meta || {};
    const getUpdatedConsultationsSuccess = page === 1 ? consultationsActions.getUpdatedConsultationsSuccess : consultationsActions.getUpdatedConsultationsNextPageSuccess;
    const { data } = yield call(processRequest, `/consultations/last_updates?page=${page}&per_page=${per_page}`, 'GET');

    if(data.consultation) {
      yield put(getUpdatedConsultationsSuccess(data.consultation));
    } else if(data.error_message) {
      yield put(consultationsActions.getUpdatedConsultationsError(data));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(consultationsActions.getUpdatedConsultationsError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess(statusText));
      yield put(replace(ROUTES.HOME));
    } else if(status === 403) {
      yield put(replace(ROUTES.PROFILE, {tab: 3}));
    } else {
      yield put(consultationsActions.getUpdatedConsultationsError('Internal server error.'));
    }
  }
}

export function* watchConsultationsSagas() {
  yield takeEvery(CONSULTATIONS_ACTIONS.GET_CONSULTATION, handleGetConsultation);
  yield takeEvery(CONSULTATIONS_ACTIONS.SAVE_CONSULTATION, handleSaveConsultation);
  yield takeEvery(CONSULTATIONS_ACTIONS.EDIT_CONSULTATION, handleEditConsultation);
  yield takeEvery(CONSULTATIONS_ACTIONS.DELETE_CONSULTATION, handleDeleteConsultation);
  yield takeEvery(CONSULTATIONS_ACTIONS.INCREMENT_SPONSOR_CLICK, handleIncrementSponsorClick);
  yield takeEvery(CONSULTATIONS_ACTIONS.INCREMENT_CONSULTATION_VIEW, handleIncrementConsultationView);
  yield takeEvery(CONSULTATIONS_ACTIONS.GET_UPDATED_CONSULTATIONS, handleGetUpdatedConsultations);
};
