import React from 'react';

export const TopSection = ({title, img, description, handleButtonClick}) => {
  const onButtonClick = () => {
    handleButtonClick('Order Your Free Product Samples');
  };
  return (
    <div className="page__main-block main-block">
      <div className="main-block__container _container">
        <div className="main-block__body">
          <div className="order">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfIZwV0rvfa1-lLKy9enVIsn0FhmjHLdmoW5PuV3nBOqBwWiw/viewform" target="_blank" onClick={onButtonClick}>
              <button className="order">
                Order Your Free Product Samples
              </button>
            </a>
          </div>
          <div className="body-desc">
            <h1 className="main-block__title">{title}</h1>
            <div className="main-block__text">
              <span>L'Oréal Active Cosmetics Education Hub</span>
            </div>
            <div className="main-block__desc">
              {description}
            </div>
          </div>
        </div>
      </div>
      <div className="main-block__image _ibg">
        <img src={require("../img/banner.png")} alt="cover" />
      </div>
    </div>
  );
};
