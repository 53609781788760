import React from "react";

export const Socials = () => {
  return (
    <div className="socials">
      <h1>Follow the brand's social networks on Instagram</h1>
      <div className="instagram-links">
        <div className="media-container">
          <img src={require("../../img/svg/black-instagram-copy.svg")} alt="" />
          <a href="https://www.instagram.com/larocheposay/" target="_blank">
            <p>@larocheposay</p>
          </a>
        </div>
        <div className="media-container">
          <img src={require("../../img/svg/black-instagram-copy.svg")} alt="" />
          <a href="https://www.instagram.com/ceraveuki/" target="_blank">
            <p>@ceraveuki</p>
          </a>
        </div>
        <div className="media-container">
          <img src={require("../../img/svg/black-instagram-copy.svg")} alt="" />
          <a href="https://www.instagram.com/vichylaboratoires/" target="_blank">
            <p>@vichylaboratoires</p>
          </a>
        </div>
        <div className="media-container">
          <img src={require("../../img/svg/black-instagram-copy.svg")} alt="" />
          <a href="https://www.instagram.com/skinceuticals/" target="_blank">
            <p>@skinceuticals</p>
          </a>
        </div>
      </div>
    </div>
  );
};
