import React, { Component } from 'react';
import { AuthHeader } from '../../';
import mailIcon from '../../../imgs/mail.svg';

class ContactUs extends Component {
  constructor() {
    super();

    this.state = {
      name: '',
      email: '',
      body: '',
    };
  }

  componentWillMount() {
    const { globalActions: { toggleFooter } } = this.props;

    toggleFooter(true);
  }

  componentWillUnmount() {
    const { globalActions: { toggleFooter } } = this.props;

    toggleFooter(false);
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = () => {
    const {sendMail} = this.props;
    const {email, name, body} = this.state;

    sendMail({email, name, body});

    this.setState({body: '', name: '', email: ''});
  }

  render() {
    const {name, email, body} = this.state;
    const isValid = name && email && body;

    return (
      <main className='page__contact'>
        <div className="contacts__block">
          <div className="contacts__page-container">
            <div className="contacts__page-content">
              <div className="contacts__desc">
                <h1>CONTACT US</h1>
                <span>
                  If you need to contact us for any reason, please email us,
                </span>
                <div className="email-block">
                  <img src={mailIcon} alt="" />
                  <p>Email</p>
                </div>
                <h2><a href='mailto:support@gpconsult.ie'>support@gpconsult.ie</a></h2>
              </div>
            </div>
          </div>
        </div>
        <div className="contact__form">
          <div className="form__container">
            <div className="form__content">
              <h1>Contact Form</h1>
              <div className="contact-inputs">
                <input type="text" className="contacts" name='name' value={name} placeholder='Your Name' onChange={this.handleInputChange} />
                <input type="email" className="contacts" name='email' value={email} placeholder='Your Email' onChange={this.handleInputChange} />
                <input type="text" className="contacts" name='body' value={body} id='message' placeholder='Type your message here' onChange={this.handleInputChange} />
              </div>
              <button className="send-btn" onClick={this.handleSubmit} disabled={!isValid}>Send</button>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ContactUs;